import { MyUser } from 'types/api/user';

export const initHeap = () => {
  if (!process.env.HEAP_ENABLED) {
    return;
  }

  const HEAP_DEVELOPMENT_APP_ID = '3826288385';
  const HEAP_PRODUCTION_APP_ID = '4041714644';

  let appId;
  if (process.env.WEB_ENV === 'development' || process.env.WEB_ENV === 'dev' || process.env.WEB_ENV === 'alpha') {
    appId = HEAP_DEVELOPMENT_APP_ID;
  } else {
    appId = HEAP_PRODUCTION_APP_ID;
  }

  const script = document.createElement('script');

  script.innerHTML = `
    window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
    heap.load("${appId}");
  `;

  document.head.append(script);
};

export const identifyUser = ({ firstName, lastName, email, organization, role }: MyUser) => {
  window.heap?.identify(email);
  window.heap?.clearEventProperties();
  window.heap?.addEventProperties({
    email,
    displayName: `${firstName} ${lastName}`,
    organization: organization?.name,
    organizationFullName: organization?.full_name,
    role: role?.name,
    version: process.env.VERSION,
  });
};
