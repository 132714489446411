import i18n from 'i18next';

import { Link } from 'react-router-dom';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const PRODUCT_PERFORMANCE_REPORT_FIELDS = [
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.product-number')
      ? i18n.t('reports:product-performance-report.columns.product-number')
      : 'Product #',
    key: 'record_id',
    id: 'record_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.record_id}
      </Link>
    ),
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.sku-number')
      ? i18n.t('reports:product-performance-report.columns.sku-number')
      : 'SKU #',
    key: 'sku_number',
    id: 'sku_number',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.stock-number')
      ? i18n.t('reports:product-performance-report.columns.stock-number')
      : 'Stock #',
    key: 'stock_number',
    id: 'stock_number',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => (
      <Link className='table-row-link' id='app-link' onClick={e => e.stopPropagation()} to={`/skus/${row.sku_uuid}`}>
        {row.stock_number}
      </Link>
    ),
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.description')
      ? i18n.t('reports:product-performance-report.columns.description')
      : 'Description',
    key: 'description',
    id: 'description',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.sold-quantity')
      ? i18n.t('reports:product-performance-report.columns.sold-quantity')
      : 'Sold Quantity',
    key: 'quantity_sold',
    id: 'quantity_sold',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    render: (row: ReportRow) => row.quantity_sold || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.start-quantity')
      ? i18n.t('reports:product-performance-report.columns.start-quantity')
      : 'Start Quantity',
    key: 'start_quantity',
    id: 'start_quantity',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => row.start_quantity || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.end-quantity')
      ? i18n.t('reports:product-performance-report.columns.end-quantity')
      : 'End Quantity',
    key: 'end_quantity',
    id: 'end_quantity',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    render: (row: ReportRow) => row.end_quantity || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.start-cost')
      ? i18n.t('reports:product-performance-report.columns.start-cost')
      : 'Start Cost',
    key: 'start_cost',
    id: 'start_cost',
    render: (row: ReportRow) => formatMoney(row.start_cost) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.end-cost')
      ? i18n.t('reports:product-performance-report.columns.end-cost')
      : 'Cost End',
    key: 'end_cost',
    id: 'end_cost',
    render: (row: ReportRow) => formatMoney(row.end_cost) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.cost-of-sales')
      ? i18n.t('reports:product-performance-report.columns.cost-of-sales')
      : 'Cost of Sales',
    key: 'cost_of_goods_sold',
    id: 'cost_of_goods_sold',
    render: (row: ReportRow) => formatMoney(row.cost_of_goods_sold) || 0,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.turnover-ratio')
      ? i18n.t('reports:product-performance-report.columns.turnover-ratio')
      : 'Turnover Ratio',
    key: 'turnover_ratio',
    id: 'turnover_ratio',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => row.turnover_ratio?.toFixed(2) || 0,
  },
  {
    name: i18n.hasResourceBundle('en', 'reports:product-performance-report.columns.cost-ratio')
      ? i18n.t('reports:product-performance-report.columns.cost-ratio')
      : 'Cost Ratio',
    key: 'cost_ratio',
    id: 'cost_ratio',
    type: 'NUMBER',
    isDefault: true,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => row.cost_ratio?.toFixed(2) || 0,
  },
];
