import { isPreviewDeploy } from 'components/PreviewDeploy';
import { formatPhoneNumber as formatPhoneNumberUS, formatPhoneNumberIntl } from 'react-phone-number-input';
import { AllReports, ReportType } from 'types/api/report';
import { Deployment } from 'utils/deployment';
import { formatMoney } from 'utils/money';
import { DailySummaryPrintCSS } from './print-css/DailySummary';

function Encode(id) {
  return btoa(id);
}

function Decode(s) {
  return Number(atob(s));
}

function GetImageForFile(filename) {
  // Get the extension
  const parts = filename.split('.');

  switch (parts[parts.length - 1]) {
    case 'bmp':
    case 'jpg':
    case 'jpeg':
    case 'png':
      return 'image.png';
    case 'doc':
    case 'docx':
      return 'doc.png';
    case 'pdf':
      return 'pdf.png';
    case 'ppt':
    case 'pptx':
      return 'ppt.png';
    case 'xls':
    case 'xlsx':
      return 'excel.png';
    default:
      return 'file.png';
  }
}

function GetMimeFromExtension(ext) {
  switch (ext) {
    case 'ai':
      return 'application/illustrator';
    case 'csv':
      return 'text/csv';
    case 'dmg':
      return 'application/x-apple-diskimage';
    case 'doc':
    case 'docx':
      return 'application/msword';
    case 'eps':
      return 'application/postscript';
    case 'gif':
      return 'image/gif';
    case 'pdf':
      return 'application/pdf';
    case 'png':
      return 'image/png';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'otf':
      return 'font/otf';
    case 'ppt':
    case 'pptx':
      return 'application/vnd.ms-powerpoint';
    case 'psd':
      return 'image/vnd.adobe.photoshop';
    case 'ttf':
      return 'font/ttf';
    case 'xls':
    case 'xlsx':
      return 'application/vnd.ms-excel';
    case 'zip':
      return 'application/zip';
    default:
      return 'text/plain';
  }
}

function IsImage(filename) {
  // Get the extension
  const parts = filename.split('.');

  switch (parts[parts.length - 1]) {
    case 'bmp':
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return true;
    default:
      return false;
  }
}

function GetSizeForFile(size) {
  if (size < 1000) {
    return `${size} bytes`;
  }

  if (size < 1000000) {
    return `${Math.round(size / 1000)} kB`;
  }

  if (size < 1000000000) {
    return `${Math.round(size / 1000000)} mB`;
  }

  if (size < 1000000000000) {
    return `${Math.round(size / 1000000000)} gB`;
  }
}

function GetTimeString(date) {
  const d = new Date(`${date} UTC`);

  let hours = d.getHours();
  let ampm = 'AM';
  if (hours >= 12) {
    ampm = 'PM';

    if (hours > 12) {
      hours -= 12;
    }
  }

  if (hours === 0) {
    hours += 12;
  }

  return `${hours}:${d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()} ${ampm}`;
}

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

function getOrdered(data) {
  const ordered = [];
  for (let i = 0; i < data.length; i++) {
    const list = data[i];
    ordered.push(list.index);
  }

  return ordered;
}

function printContent(id, reportTitle, reportFilters) {
  const isDailySummary = id === AllReports[ReportType.AccountingSummary].key;
  const isDailyOnAccount = id === AllReports[ReportType.DailyOnAccount].key;
  const isEodReport = id === AllReports[ReportType.AccountingEndOfDay].key;

  const mywindow = window.open('', 'PRINT', 'height=400,width=600');
  // open head
  mywindow.document.write('<html><head>');

  if (isDailySummary || isDailyOnAccount) {
    // apply CSS here to hide 'empty' buttons as well as indent over child rows for expandable components
    // also rotates and translates content to landscape orientation for Chrome
    mywindow.document.head.innerHTML += `<style>${DailySummaryPrintCSS}</style>`;
  }

  // close head
  mywindow.document.write('</head><body>');

  // add title
  if (reportTitle !== undefined) {
    mywindow.document.write(`<h2>${reportTitle}</h2>`);
  }

  // add active filters
  if (reportFilters !== undefined) {
    mywindow.document.write(
      `<div><h4 style="display: inline-flex; margin-right: 10px;">Active Filters: </h4>${reportFilters}</div>`
    );
  }

  let content = document.getElementById(id).innerHTML;

  // if eod report, transform input fields to text fields formatted for money display
  // only 'Forecast' field on EOD report will have input fields for row columns
  if (isEodReport) {
    // create html object of content
    const html = document.createElement('div');
    html.innerHTML = content;

    // get input elements
    const inputElements = html.getElementsByTagName('input');

    // transform to linked array
    [].slice
      .call(inputElements)
      // then loop through inputs & mutate display html
      .map(element => (element.outerHTML = `<p>${formatMoney(element.value)}</p>`));

    // update content
    content = html.innerHTML;
  }

  // add more options here
  const skipForceSmallFontSize = isDailySummary;

  // force small font size for table cells unless specified
  if (!skipForceSmallFontSize) {
    content = content.replace(/<th /g, '<th style="font-size: 9px" ');
    content = content.replace(/<td /g, '<td style="font-size: 9px" ');
  }

  mywindow.document.write(content);
  mywindow.document.write('</body></html>');

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10
  mywindow.print();
  mywindow.close();
  return true;
}

const renderDateRangeFilterText = filters => {
  if (filters[0] && filters[1]) {
    return `${filters[0].format('l')} to ${filters[1].format('l')}`;
  }
  return '';
};

const renderDateRangeObjText = (filters, format = 'l') => {
  if (filters.start && filters.end && filters.start.format('l') === filters.end.format('l')) {
    return filters.start.format(format);
  }
  if (filters.start && filters.end) {
    return `${filters.start.format(format)} to ${filters.end.format(format)}`;
  }
  return '';
};

const ParseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const setAppFavicon = () => {
  let suffix;

  if (isPreviewDeploy()) {
    suffix = 'cookie';
  } else if (process.env.NODE_ENV === 'development') {
    suffix = 'local';
  } else if (process.env.WEB_ENV === 'dev') {
    suffix = 'dev';
  } else if (process.env.WEB_ENV === 'alpha') {
    suffix = 'alpha';
  }

  if (!suffix) return;

  try {
    const favicon = document.head.querySelector('link[rel=icon]');
    favicon.href = `/favicon-${suffix}.ico`;
  } catch (err) {} // eslint-disable-line no-empty
};

const getBuildDetails = () => ({
  isPreviewDeploy: isPreviewDeploy(),
  ...process.meta.build,
  api: {
    ...process.meta.build.api,
    baseUrl: Deployment.host,
  },
});

const updatePasswordStrength = (newInputValue, setStrengthFunction) => {
  const pw = newInputValue;
  const lengthGreaterThanEight = pw.length >= 8;
  const containsLowercaseOrUppercaseCharacter = /[a-zA-Z]/.test(pw);
  const containsDigit = /[\d]/.test(pw);
  const containsUppercaseCharacter = /[A-Z]/.test(pw);
  const containsSpecialCharacter = /[~`!#$%^&*+=\-[\]\\';,./{}|\\":<>?()@_]/g.test(pw);

  // min length check:
  if (pw.length < 6) {
    setStrengthFunction(0);
    return;
  }

  let percentage = 0;
  if (lengthGreaterThanEight) {
    percentage += 20;
  }
  if (containsLowercaseOrUppercaseCharacter) {
    percentage += 20;
  }
  if (containsDigit) {
    percentage += 20;
  }
  if (containsUppercaseCharacter) {
    percentage += 20;
  }
  if (containsSpecialCharacter) {
    percentage += 20;
  }

  setStrengthFunction(percentage);
};

const generateAlphanumericId = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const formatPhoneNumber = phone => {
  if (!phone) {
    return '';
  }

  const isUSNumber = phone => phone.includes('+1');
  let newPhone = phone;
  if (!phone.includes('+')) {
    newPhone = `+${phone}`;
  }
  if (isUSNumber(newPhone)) {
    return formatPhoneNumberUS(newPhone) || phone;
  }
  return formatPhoneNumberIntl(newPhone) || phone;
};

const isDataCurrencyString = inputString => {
  return typeof inputString === 'string' && inputString.includes('$');
};

const checkInRange = (a, b) => {
  const diff = Math.abs(a - b);

  if (diff < a / 10) {
    return true;
  }

  return false;
};

const capitalizeFirstLetter = string => {
  if (!string || string.length === 0) {
    return '';
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export {
  Encode,
  Decode,
  GetImageForFile,
  GetSizeForFile,
  IsImage,
  GetTimeString,
  GetMimeFromExtension,
  ParseJwt,
  getOrdered,
  printContent,
  humanFileSize,
  renderDateRangeFilterText,
  renderDateRangeObjText,
  setAppFavicon,
  getBuildDetails,
  updatePasswordStrength,
  generateAlphanumericId,
  formatPhoneNumber,
  isDataCurrencyString,
  checkInRange,
  capitalizeFirstLetter,
};
