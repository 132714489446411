import { setMerchProductId, setMerchProductToSearchResult } from 'redux/actions/merch';
import { openModal, _apiRequest } from 'redux/actions/system';
import {
  ADD_PRODUCT,
  DELETE_PRODUCT_ATTACHMENTS,
  DELETE_PRODUCT_IMAGE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_META,
  GET_PRODUCT,
  GET_PRODUCT_ATTACHMENTS,
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_IMAGES,
  GET_PRODUCT_INVENTORY,
  GET_PRODUCT_MOVEMENT,
  GET_PRODUCT_SALES,
  GET_PRODUCT_TRANSACTIONS,
  GET_PRODUCT_WISHLIST,
  GET_REORDER_PRODUCTS,
  GET_WARRANTY_ITEMS,
  MERGE_PRODUCTS,
  RESET_NEW_PRODUCT,
  SET_ATTRIBUTE_MAP,
  SET_PRODUCT_ON_ORDER,
  SET_REORDER_VENDOR,
  SET_STANDARD_ATTRIBUTES,
} from 'redux/constants/action-types';
import { NEW_PRODUCT_MODAL } from 'redux/constants/modals';
import history from 'utils/history';
import * as Request from 'utils/request';

export const addProduct =
  (payload, key, redo = false, cb) =>
  async dispatch =>
    _apiRequest({
      actionBase: ADD_PRODUCT,
      dispatch,
      method: 'Post',
      payload,
      path: 'product',
      returnPayloadOnSuccess: true,
      handleStatus: status => {
        switch (status) {
          case 400:
            window.showNotification(
              'error',
              'Error Adding Product',
              `An error occurred. Please make sure the product does not already exist and try again.`
            );
            break;
          default:
            break;
        }
      },
      callback: data => {
        if (data) {
          const { uuid } = data;
          if (!uuid) return;

          cb && cb(data);

          window.showNotification('success', `New product created.`, `Product #: ${data.reference}`);

          switch (key) {
            case 'products':
              if (!redo) {
                history.push(`/products/${uuid}`);
              }
              break;
            case 'receives':
              if (!redo) {
                dispatch(setMerchProductId(uuid));
                dispatch(setMerchProductToSearchResult([data]));
                dispatch(openModal(NEW_PRODUCT_MODAL));
                dispatch(setNewProduct(data));
              }

              break;
            default:
              break;
          }
        }
      },
    });

export const setNewProduct = payload => ({ type: ADD_PRODUCT, payload });

export const mergeProducts = payload => async dispatch =>
  _apiRequest({
    actionBase: MERGE_PRODUCTS,
    dispatch,
    method: 'Post',
    path: `products/merge`,
    payload,
  });

export const editProduct = (payload, vendor?: any, updateInventory?: any, cb?: () => void) => async dispatch => {
  const response = await Request.Put('product', payload);

  if (!response?.status) {
    window.showError('Error updating product');
    return;
  }

  if (!payload.active && response.status === 417) {
    window.showNotification(
      'error',
      'SKUs In Stock',
      'There are still Stock #s in stock for this product. In order to deactivate it, please move all the in stock Stock #s to a different product.'
    );

    return;
  }

  if (response.error) {
    console.error(response.error);
    return;
  }

  if (response.data) {
    dispatch({
      type: EDIT_PRODUCT,
      payload,
      vendor,
    });
  }

  if (updateInventory) {
    dispatch(getProductInventory(payload.id, payload.uuid));
  }

  cb && cb();
};

export const editProductMeta = payload => ({
  type: EDIT_PRODUCT_META,
  payload,
});

export const resetNewProduct = () => ({ type: RESET_NEW_PRODUCT });

export const getProduct = (refId, cb?: (data: any) => void) => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT,
    dispatch,
    method: 'Get',
    path: `product?id=${refId}`,
    callback: data => {
      cb && cb(data);
    },
  });

export const getProductSales = (id, uuid) => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT_SALES,
    dispatch,
    method: 'Get',
    path: `product/sales?id=${id}`,
    metaDispatch: { uuid },
  });

export const getProductInventory =
  (refId, uuid, isMasterProduct = false) =>
  async dispatch =>
    _apiRequest({
      actionBase: GET_PRODUCT_INVENTORY,
      dispatch,
      method: 'Get',
      path: `product/stock?id=${refId}&all=1&isMasterProduct=${isMasterProduct ? 1 : 0}`,
      metaDispatch: { uuid },
    });

export const getProductAttachments = (refId, uuid) => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT_ATTACHMENTS,
    dispatch,
    method: 'Get',
    path: `product/attachments?id=${refId}`,
    metaDispatch: { uuid },
  });

export const detachProductAttachment = (refId, uuid, attachmentId) => async dispatch =>
  _apiRequest({
    actionBase: DELETE_PRODUCT_ATTACHMENTS,
    dispatch,
    method: 'Put',
    path: `product/attachment`,
    payload: {
      id: refId,
      attachment_id: attachmentId,
      uuid,
    },
    returnPayloadOnSuccess: true,
  });

export const getProductImages = (refId, uuid) => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT_IMAGES,
    dispatch,
    method: 'Get',
    path: `product/images?id=${refId}`,
    metaDispatch: { uuid },
  });

export const detachProductImage = (refId, uuid, attachmentId) => async dispatch =>
  _apiRequest({
    actionBase: DELETE_PRODUCT_IMAGE,
    dispatch,
    method: 'Put',
    path: `product/image`,
    payload: {
      id: refId,
      attachment_id: attachmentId,
      uuid,
    },
    returnPayloadOnSuccess: true,
  });

export const getProductValues = (refId, uuid, cb?: (data: any) => void) => async dispatch => {
  const response = await _apiRequest({
    actionBase: GET_PRODUCT_ATTRIBUTES,
    dispatch,
    method: 'Get',
    path: `product/values?id=${refId}`,
    metaDispatch: { uuid },
    callback: data => {
      cb && cb(data);
    },
  });

  if (response.data) {
    dispatch({
      type: SET_ATTRIBUTE_MAP,
      uuid,
      payload: response.data,
    });
  }
};

export const setProductOnOrder = ref => ({
  type: SET_PRODUCT_ON_ORDER,
  payload: ref,
});

export const setAttributeMap = (uuid, payload) => ({
  type: SET_ATTRIBUTE_MAP,
  uuid,
  payload,
});

export const getProductTransactions = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT_TRANSACTIONS,
    dispatch,
    method: 'Get',
    path: `product/orders?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const getProductWishlist = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT_WISHLIST,
    dispatch,
    method: 'Get',
    path: `product/wishlist?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const getProductMovement = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_PRODUCT_MOVEMENT,
    dispatch,
    method: 'Get',
    path: `product/movement?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const getReorderProducts = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_REORDER_PRODUCTS,
    dispatch,
    method: 'Get',
    path: `product/reorder?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const getWarrantyItems = uuid => async dispatch =>
  _apiRequest({
    actionBase: GET_WARRANTY_ITEMS,
    dispatch,
    method: 'Get',
    path: `product/warranty?id=${uuid}`,
    metaDispatch: { uuid },
  });

export const setReorderVendor = payload => ({
  type: SET_REORDER_VENDOR,
  payload,
});

export const editStandardAttributes = (id: number, uuid: string, payload: any) => async dispatch => {
  _apiRequest({
    actionBase: SET_STANDARD_ATTRIBUTES,
    dispatch,
    method: 'Put',
    path: `products/${id}/attributes`,
    payload,
    returnPayloadOnSuccess: true,
    metaDispatch: { uuid },
  });
};
