import { setGlobalTheme } from '@atlaskit/tokens';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Routes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { LayoutProvider } from 'components/Main/Layout';
import { initHeap } from 'integrations/heap';
import { initIntercom } from 'integrations/intercom';
import { initSentry } from 'integrations/sentry';
import { getStore } from 'redux/store';
import history from 'utils/history';
import { getBuildDetails, setAppFavicon } from 'utils/utils';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'moment/min/locales';
import './i18n';

const PrintRoute = React.lazy(() => import(/* webpackChunkName: "print-route" */ './components/Print'));

const AppRoute = React.lazy(() => import(/* webpackChunkName: "app-route" */ './App'));

const store = getStore();

const Root = () => {
  useEffect(() => {
    global.build = getBuildDetails();

    // initialize integrations
    initSentry(store.getState().root.version);
    initIntercom();
    initHeap();

    setAppFavicon();

    if (process.env.WEB_ENV !== 'app') {
      // for easy debugging of redux store/sentry config
      global.store = store;
      global.Sentry = Sentry;
    }
  }, []);

  useEffect(() => {
    setGlobalTheme({
      colorMode: 'light',
      UNSAFE_themeOptions: {
        brandColor: '#575bdc',
      },
    });
  }, []);

  return (
    <Provider store={store}>
      <HistoryRouter history={history}>
        <LayoutProvider>
          <React.Suspense fallback={null}>
            <Routes>
              <Route element={<PrintRoute />} path='/print' />
              <Route element={<AppRoute />} path='*' />
            </Routes>
          </React.Suspense>
        </LayoutProvider>
      </HistoryRouter>
    </Provider>
  );
};

render(<Root />, document.getElementById('root'));

serviceWorkerRegistration.unregister();
