export enum AccountsReceivableType {
  Collection = 'collection',
  Payment = 'payment',
  StoreCredit = 'storecredit',
  Interest = 'interest',
}

export interface AccountsReceivable {
  id: number;
  amount: number;
  amount_paid: number;
  amount_string: string;
  balance: number;
  customer_uuid: string;
  order_uuid: string;
  payment_date: string;
  payment_date_string: string;
  payment_uuid: string;
  transaction_number: string;
  type?: string;
}
