import {
  ADD_ACCOUNTING_BUCKET,
  CREATE_OR_UPDATE_ACCOUNTING_PAYOUT,
  EDIT_ACCOUNTING_BUCKET,
  GET_ACCOUNTING_BUCKETS,
  GET_ACCOUNTING_MONTH,
  GET_ACCOUNTING_PREFERENCES,
  GET_CLOSE_REGISTER,
  GET_DAILY_RECEIPTS,
  GET_DAILY_SUMMARY,
  GET_DAILY_SUMMARY_TIEOUT,
  GET_END_OF_DAY_PAYMENTS,
  GET_END_OF_DAY_SALES,
  GET_HEADCOUNT,
  UpdateReceiptBucketAction,
  UPDATE_ACCOUNTING_DATE,
  UPDATE_ACCOUNTING_PREFERENCE,
  UPDATE_DAILY_RECEIPT,
} from 'redux/actions/accounting';
import { AccountingBucket } from 'types/api/accounting-bucket';
import { AccountingPreference } from 'types/api/accounting-preference';
import { DailyReceipt } from 'types/api/daily-receipt';
import { PaymentMethodType } from 'types/api/order';
import { AccountingMonth, DailySummary, EndOfDayPayments, EndOfDaySales, Headcount } from 'types/api/reports';
import { successReqActionType } from '../actions/helpers';
import { RESET_ALL_DATA } from '../constants/action-types';

export interface AccountingBucketsState {
  accountingBuckets: AccountingBucket[];
  accountingMonths: { [key: string]: AccountingMonth };
  dailyReceipts: DailyReceipt[];
  endOfDaySales?: EndOfDaySales;
  endOfDayPayments?: EndOfDayPayments;
  dailySummary: DailySummary;
  selectedDates: any;
  closeRegister?: any;
  headcount: Headcount[];
  preferences: AccountingPreference[] | null;
}

const initialState: AccountingBucketsState = {
  accountingBuckets: [],
  accountingMonths: {},
  dailyReceipts: [],
  endOfDaySales: {
    day: '',
    months: [],
    previous_year_months: [],
    location_years: [],
    sales: [],
    highlights: [],
    deposits: [],
  },
  endOfDayPayments: {
    day: '',
    day_payments: [],
    month_payments: [],
    year_payments: [],
    payments: [],
  },
  dailySummary: {
    clients: [],
    collections: [],
    sales: [],
    payments: [],
    payouts: [],
    increase_money_held: [],
    decrease_money_held: [],
    gift_cards: [],
    store_credit: [],
    tieout: {
      amount: 0,
      tax: 0,
      total: 0,
      increase_money_held: 0,
      decrease_money_held: 0,
      payments: 0,
      dollars_to_tie: 0,
    },
  },
  selectedDates: {},
  closeRegister: {
    sales: [],
    inputs: [],
  },
  headcount: [],
  preferences: null,
};

const reducer = (state = initialState, action): AccountingBucketsState => {
  const { accountingBuckets } = state;
  switch (action.type) {
    case successReqActionType(GET_ACCOUNTING_BUCKETS):
      return {
        ...state,
        accountingBuckets: action.payload,
      };
    case successReqActionType(ADD_ACCOUNTING_BUCKET):
      return {
        ...state,
        accountingBuckets: [...accountingBuckets, action.payload],
      };
    case successReqActionType(EDIT_ACCOUNTING_BUCKET):
      const updatedNewAccountingBuckets = [...accountingBuckets];
      const { payload } = action;
      const currentItem = updatedNewAccountingBuckets.find(pt => pt.id === payload.id);
      const currentItemIndex = updatedNewAccountingBuckets.findIndex(pt => pt.id === payload.id);
      const newItem = { ...currentItem, ...payload };
      updatedNewAccountingBuckets.splice(currentItemIndex, 1, newItem);
      return {
        ...state,
        accountingBuckets: updatedNewAccountingBuckets,
      };
    case successReqActionType(GET_ACCOUNTING_MONTH):
      if (!action.payload) {
        return state;
      }

      const { month, year, location_id } = action.payload;

      return {
        ...state,
        accountingMonths: {
          ...state.accountingMonths,
          [`${month}-${year}-${location_id}`]: action.payload,
        },
      };
    case successReqActionType(GET_END_OF_DAY_SALES):
      return {
        ...state,
        endOfDaySales: action.payload,
      };
    case successReqActionType(GET_END_OF_DAY_PAYMENTS):
      return {
        ...state,
        endOfDayPayments: action.payload,
      };
    case successReqActionType(GET_DAILY_RECEIPTS):
      return {
        ...state,
        dailyReceipts: action.payload,
      };
    case UPDATE_DAILY_RECEIPT:
      const { accounting_bucket_id, orderItemID } = action.payload as UpdateReceiptBucketAction;
      const dailyReceipts = [...state.dailyReceipts];
      const itemIndex = dailyReceipts.findIndex(item => item.order_item_id === orderItemID);
      if (itemIndex === -1) {
        return state;
      }
      const accountingBucket = state.accountingBuckets.find(bucket => bucket.id === accounting_bucket_id);
      if (!accountingBucket) {
        return state;
      }

      dailyReceipts.splice(itemIndex, 1, {
        ...dailyReceipts[itemIndex],
        accounting_bucket: accountingBucket,
      });
      return {
        ...state,
        dailyReceipts,
      };
    case UPDATE_ACCOUNTING_DATE:
      return {
        ...state,
        selectedDates: {
          ...state.selectedDates,
          [action.payload.key]: action.payload.date,
        },
      };
    case CREATE_OR_UPDATE_ACCOUNTING_PAYOUT:
      const updatedPayout = action.payload;
      const indexOf = state.dailySummary.payouts.findIndex(p => p.id === updatedPayout.id);
      if (indexOf > -1) {
        const payouts = [...state.dailySummary.payouts];
        payouts.splice(indexOf, 1, updatedPayout);
        return {
          ...state,
          dailySummary: {
            ...state.dailySummary,
            payouts,
          },
        };
      }
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          payouts: [...state.dailySummary.payouts, updatedPayout],
        },
      };
    case successReqActionType(GET_DAILY_SUMMARY):
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          ...action.payload,
          payments: action.payload.payments.filter(payment => payment.payment_code !== PaymentMethodType.StoreCredit),
          increase_money_held: action.payload.increase_money_held.filter(
            payment => payment.payment_code !== PaymentMethodType.StoreCredit
          ),
          decrease_money_held: action.payload.decrease_money_held.filter(
            payment => payment.payment_code !== PaymentMethodType.StoreCredit
          ),
        },
      };
    case successReqActionType(GET_DAILY_SUMMARY_TIEOUT):
      return {
        ...state,
        dailySummary: {
          ...state.dailySummary,
          tieout: action.payload,
        },
      };
    case successReqActionType(GET_CLOSE_REGISTER):
      return {
        ...state,
        closeRegister: action.payload,
      };
    case successReqActionType(GET_HEADCOUNT):
      return {
        ...state,
        headcount: action.payload,
      };
    case successReqActionType(GET_ACCOUNTING_PREFERENCES):
      return {
        ...state,
        preferences: action.payload,
      };
    case successReqActionType(UPDATE_ACCOUNTING_PREFERENCE):
      const preferences = [...(state.preferences || [])];
      const indexOfPreference = preferences.findIndex(pref => pref.id === action.payload.id);
      preferences.splice(indexOfPreference, 1, action.payload);
      return {
        ...state,
        preferences,
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
