import { Link } from 'react-router-dom';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const LINKED_PRODUCTS_REPORT_FIELDS = [
  {
    name: 'Transaction Date',
    id: 'transaction_date',
    key: 'transaction_date',
    sortable: true,
    filterable: true,
    type: 'DATE',
    isDefault: true,
    render: (row: ReportRow) => formatLocalDate(row.transaction_date),
  },
  {
    name: 'Transaction #',
    id: 'transaction_id',
    key: 'transaction_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transactions/${row.order_uuid}`}
      >
        {row.transaction_id}
      </Link>
    ),
  },
  {
    name: 'Status',
    id: 'status',
    key: 'status',
    isDefault: true,
    sortable: true,
  },
  {
    name: 'Location',
    id: 'location_name',
    key: 'location_name',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Client Name',
    id: 'customer_name',
    key: 'customer_name',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_name}
      </Link>
    ),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
  },
  {
    name: 'Vendor Name',
    id: 'vendor_name',
    key: 'vendor_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Vendor Code',
    id: 'vendor_code',
    key: 'vendor_code',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Product #',
    id: 'product_number',
    key: 'product_number',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/products/${row.product_uuid}`}
      >
        {row.product_number}
      </Link>
    ),
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
  },
  {
    name: 'Description',
    id: 'description',
    key: 'description',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Subtotal',
    id: 'subtotal',
    key: 'subtotal',
    render: (row: ReportRow) => formatMoney(row.subtotal),
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Tax',
    id: 'tax',
    key: 'tax',
    render: (row: ReportRow) => formatMoney(row.tax),
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Total',
    id: 'total',
    key: 'total',
    render: (row: ReportRow) => formatMoney(row.total),
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Client Total Sales',
    id: 'total_sales',
    key: 'total_sales',
    render: (row: ReportRow) => formatMoney(row.total_sales),
    sortable: true,
    isDefault: false,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Amount Paid',
    id: 'amount_paid',
    key: 'amount_paid',
    render: (row: ReportRow) => formatMoney(row.amount_paid),
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Balance',
    id: 'balance',
    key: 'balance',
    render: (row: ReportRow) => formatMoney(row.balance),
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Salesperson #',
    id: 'salesperson_id',
    key: 'salesperson_id',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson Name',
    id: 'salesperson_name',
    key: 'salesperson_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    key: 'note',
    id: 'note',
    render: (row: ReportRow) => row.note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Internal Note',
    key: 'internal_note',
    id: 'internal_note',
    render: (row: ReportRow) => row.internal_note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
];
