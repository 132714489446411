import { Location } from './location';

export interface OrderType {
  id: number;
  uuid: string;
  accounting_bucket_id?: number;
  active: boolean;
  created: string;
  custom: boolean;
  is_payment_required: boolean;
  name: string;
  prefix: string;
  sort_order: number;
  vat_rate: number;
}

export enum OrderTypeKey {
  Invoice = 1,
  SpecialOrder = 2,
  Layaway = 3,
  Payout = 4,
  Return = 5,
  Repair = 6,
  Refund = 7,
  Exchange = 8,
  Web = 9,
  Purchase = 12,
  Adjustment = 13,
  Wholesale = 18,
}

export const OrderTypeNames = {
  Adjustment: 'Adjustment',
  Exchange: 'Exchange',
  Invoice: 'Invoice',
  Layaway: 'Layaway',
  Payout: 'Payout',
  Purchase: 'Purchase',
  Return: 'Return',
  Refund: 'Refund',
  Repair: 'Repair',
  SpecialOrder: 'Special Order',
  Web: 'Web',
  Wholesale: 'Wholesale',
} as const;
export type OrderTypeName = typeof OrderTypeNames[keyof typeof OrderTypeNames];

export interface Order {
  id: number;
  uuid: string;
  location: Location;
  items: any[];
}

export interface OrderItem {
  id: number;
  uuid: string;
  accounting_bucket_id: number;
  amount: number;
  description: string;
  discount: number;
  hide_on_receipt: boolean;
  non_stock: boolean;
  quantity: number;
  tax: number;
  taxable: boolean;
  quote_values?: QuoteValues;
  reference_data_type?: string;
  reference_data?: any;
  account_name?: string;
}

export interface QuoteValues {
  id: number;
  value: number;
  description: string;
  quote_stock_category_id: number;
  quote_stock_category_description: string;
}

export enum PaymentMethodType {
  Cash = 'CASH',
  Check = 'CHECK',
  VISA = 'VISA',
  MasterCard = 'MASTERCARD',
  Discover = 'DISCOVER',
  AmericanExpress = 'AMEX',
  OtherCard = 'OTHERCARD',
  GiftCard = 'GIFT',
  Wire = 'WIRE',
  StoreCredit = 'SC',
  OnAccount = 'ACCT',
}

export interface JewelryRepairCost {
  name: string;
  is_category_header: boolean;
  category_name: string;
  '10k': string;
  '14k': string;
  '18k': string;
  gold: string;
  platinum: string;
  stainless_steel: string;
  items: JewelryRepairCost[];
}
