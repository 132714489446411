import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const i18nNamespaces = {
  Accounting: 'accounting',
  Admin: 'admin',
  Address: 'address',
  Asset: 'asset',
  Birdeye: 'birdeye',
  Brand: 'brand',
  BrowseInventory: 'browse-inventory',
  Clients: 'clients',
  Commission: 'commission',
  Common: 'common',
  Collection: 'collection',
  CRM: 'crm',
  ECommerce: 'ecommerce',
  Formats: 'formats',
  Inventory: 'inventory',
  JewelersMutual: 'jewelers-mutual',
  Location: 'location',
  Loupe: 'loupe',
  Manufacture: 'manufacture',
  Memo: 'memo',
  MerchandiseReceived: 'merchandise-received',
  Notifications: 'notifications',
  Order: 'order',
  Payment: 'payment',
  Podium: 'podium',
  PointOfSale: 'point-of-sale',
  Product: 'product',
  PurchaseOrder: 'purchase-order',
  Quickbooks: 'quickbooks',
  Repairs: 'repairs',
  Reports: 'reports',
  SKU: 'sku',
  System: 'system',
  Vendor: 'vendor',
  Zillion: 'zillion',
} as const;

/**
 * Translation files are within public/locales
 * These can be managed by translators and should
 * remain outside of the typical codebase to allow.
 */

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
