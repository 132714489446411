import { Link } from 'react-router-dom';
import { AccountsReceivableType } from 'types/api/accounts-receivable';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const ACCOUNTS_RECEIVABLE_REPORT_FIELDS = [
  {
    name: 'Date',
    id: 'date',
    key: 'date',
    type: 'DATE',
    filterable: true,
    render: (row: ReportRow) => formatLocalDate(row.date, 'l HH:mm:ss'),
  },
  {
    name: 'Client #',
    key: 'customer.uuid',
    id: 'customer.uuid',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row?.customer?.uuid}`}
      >
        {row.customer.record_id}
      </Link>
    ),
    type: 'LINK',
  },
  {
    name: 'Client Name',
    key: 'customer.name',
    id: 'customer.name',
    filterable: true,
  },
  {
    name: 'Type',
    key: 'type',
    id: 'type',
    render: (row: ReportRow) => {
      switch (row.type) {
        case AccountsReceivableType.Payment:
          return 'On Account';
        case AccountsReceivableType.Collection:
          return 'Collection';
        case AccountsReceivableType.StoreCredit:
          return 'Store Credit';
        case AccountsReceivableType.Interest:
          return 'Interest';
        default:
          return undefined;
      }
    },
    type: 'SELECT',
    filterable: true,
  },
  {
    name: 'Payment Code',
    key: 'payment_code',
    id: 'payment_code',
  },
  {
    name: 'Payment #',
    key: 'record_id',
    id: 'record_id',
  },
  {
    name: 'Transaction #',
    key: 'order.uuid',
    id: 'order.uuid',
    render: (row: ReportRow) =>
      row.order && (
        <Link
          className='table-row-link'
          id='app-link'
          onClick={e => e.stopPropagation()}
          to={`/transactions/${row?.order?.uuid}`}
        >
          {row.order.record_id}
        </Link>
      ),
  },
  {
    name: 'Debit Amount',
    key: 'debit_amount',
    id: 'debit_amount',
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.debit_amount),
  },
  {
    name: 'Credit Amount',
    key: 'credit_amount',
    id: 'credit_amount',
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.credit_amount),
  },
  {
    name: 'Total Balance',
    key: 'balance',
    id: 'balance',
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.balance),
  },
];
