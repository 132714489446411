import { flatMap, property } from 'lodash';
import moment from 'moment';
import { _apiRequest } from 'redux/actions/system';
import {
  GET_ACCOUNTS_RECEIVABLE_BALANCE_REPORT,
  GET_ACCOUNTS_RECEIVABLE_REPORT,
  GET_DAILY_ON_ACCOUNT_REPORT,
  GET_DAILY_PAYMENTS_REPORT,
  GET_REPORT,
  GET_REPORT_LAYOUTS,
  GET_SALES_TAX_THRESHOLD_REPORT,
  REMOVE_REPORT_LAYOUT,
  RESET_REPORT,
  RESET_REPORT_PROPERTY,
  SET_LAYOUTS_MODAL_VISIBLE,
  SET_NEW_LAYOUT_MODAL_VISIBLE,
  SET_REPORTS_ATTRIBUTE_FILTERS,
  SET_REPORTS_DRAWER_VISIBLE,
  SET_REPORT_LAYOUT,
  SET_REPORT_PROPERTIES,
  SET_REPORT_PROPERTY,
  SET_SALES_COMPARISON_COLUMNS,
  SET_STOCK_CATEGORY_PERFORMANCE_COMPARISON_COLUMNS,
} from 'redux/constants/action-types';

export const getVendorsReport = () => async (dispatch, ownState) => {
  const { vendorIds, dateFilters, age } = ownState().reports.vendor;

  const params = new URLSearchParams({
    age,
  });

  params.append('startDate', dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '');
  params.append('endDate', dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '');

  if (vendorIds) {
    params.append('vendorId', vendorIds.value);
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/vendorsReport?${params.toString()}`,
    metaDispatch: { key: 'vendor' },
  });
};

export const getVendorPerformanceReport =
  (key = 'vendorPerformance') =>
  async (dispatch, ownState) => {
    const { selectedVendors, closeDateFilters } = ownState().reports.vendorPerformance;

    const payload = {
      start_close_date: closeDateFilters.start ? moment(closeDateFilters.start).format('YYYY-MM-DD') : '',
      end_close_date: closeDateFilters.end ? moment(closeDateFilters.end).format('YYYY-MM-DD') : '',
      vendor_ids: <any>[],
    };

    if (selectedVendors && selectedVendors.length > 0) {
      // should only ever be one element in vendorIds
      payload.vendor_ids = selectedVendors.map(vendor => vendor.value);
    }

    await _apiRequest({
      actionBase: GET_REPORT,
      dispatch,
      method: 'Post',
      path: `reports/vendor-performance`,
      payload,
      metaDispatch: { key },
    });
  };

export const getSalesPerformanceReport =
  (key = 'salesPerformance') =>
  async (dispatch, ownState) => {
    const { closeDateFilters } = ownState().reports.salesPerformance;

    const payload = {
      start_close_date: closeDateFilters.start ? moment(closeDateFilters.start).format('YYYY-MM-DD') : '',
      end_close_date: closeDateFilters.end ? moment(closeDateFilters.end).format('YYYY-MM-DD') : '',
    };

    await _apiRequest({
      actionBase: GET_REPORT,
      dispatch,
      method: 'Post',
      path: `reports/sales-performance`,
      payload,
      metaDispatch: { key },
    });
  };

export const getSalesReportBody = (report, key: string, userId?: string, overrides: any = {}) => {
  const reportWithOverrides = { ...report, ...overrides };
  const {
    dateFilters,
    closeDateFilters,
    canceledDateFilters,
    salespersonIds,
    locationIds,
    vendorIds,
    linkedVendorIds,
    productTypeIds,
    categoryIds,
    types,
    clients,
    jobTypes,
    taxable,
    stock,
    split,
    skuIds,
    status,
    memo,
    attributes,
    includeRepairs,
    excludeAdjustments,
    includeJobCostGrossProfit = 1,
  } = reportWithOverrides;

  const payload = {
    is_user: key === 'mySales' ? 1 : 0,
    status,
    include_repairs: includeRepairs,
    taxable,
    stock,
    split,
    start_date: dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '',
    end_date: dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '',
    start_close_date: closeDateFilters.start ? closeDateFilters.start.format('YYYY-MM-DD') : '',
    end_close_date: closeDateFilters.end ? closeDateFilters.end.format('YYYY-MM-DD') : '',
    start_canceled_date: canceledDateFilters.start ? canceledDateFilters.start.format('YYYY-MM-DD') : '',
    end_canceled_date: canceledDateFilters.end ? canceledDateFilters.end.format('YYYY-MM-DD') : '',
    salesperson_ids: <any>[],
    client_ids: <any>[],
    location_ids: <any>[],
    vendor_ids: <any>[],
    linked_vendor_ids: <any>[],
    product_type_ids: <any>[],
    category_ids: <any>[],
    sku_ids: <any>[],
    types: <any>[],
    jobTypes: <any>[],
    attributes: <any>[],
    memo: 0,
    exclude_adjustments: excludeAdjustments,
    include_job_cost_gross_profit: includeJobCostGrossProfit,
  };

  if (userId) {
    payload.salesperson_ids = [userId];
  } else if (salespersonIds && salespersonIds.length > 0) {
    payload.salesperson_ids = flatMap(salespersonIds, property('value'));
  }

  if (clients.length) {
    payload.client_ids = flatMap(clients, property('id'));
  }

  if (locationIds && locationIds.length > 0) {
    payload.location_ids = flatMap(locationIds, property('value'));
  }

  if (types && types.length > 0) {
    payload.types = flatMap(types, property('value'));
  }

  if (vendorIds && vendorIds.length > 0) {
    payload.vendor_ids = flatMap(vendorIds, property('value'));
  }

  if (linkedVendorIds && linkedVendorIds.length > 0) {
    payload.linked_vendor_ids = flatMap(linkedVendorIds, property('value'));
  }

  if (productTypeIds && productTypeIds.length > 0) {
    payload.product_type_ids = flatMap(productTypeIds, property('value'));
  }

  if (categoryIds.length > 0) {
    payload.category_ids = flatMap(categoryIds, property('value'));
  }

  if (skuIds && skuIds.length > 0) {
    payload.sku_ids = flatMap(skuIds, property('value'));
  }

  if (jobTypes && jobTypes.length > 0) {
    payload.jobTypes = flatMap(jobTypes, property('value'));
  }

  if (attributes.length) {
    payload.attributes = attributes;
  }

  payload.memo = 0;

  if (memo) {
    payload.memo = memo.value;
  }

  return payload;
};

export const getSalesReport = (key, userId) => async (dispatch, ownState) => {
  const payload = getSalesReportBody(ownState().reports[key], key, userId);

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Post',
    path: `reports/sales-items`,
    payload,
    metaDispatch: { key },
  });
};

export const getSalesByLocation = () => async (dispatch, ownState) => {
  const key = 'salesByLocation';
  const report = ownState().reports[key];

  const {
    dateFilters,
    closeDateFilters,
    canceledDateFilters,
    salespersonIds,
    vendorIds,
    productTypeIds,
    categoryIds,
    types,
    jobTypes,
    status,
    memo,
    includeRepairs,
    excludeAdjustments,
    includeJobCostGrossProfit = 1,
  } = report;

  const payload = {
    status,
    include_repairs: includeRepairs,
    start_date: dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '',
    end_date: dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '',
    start_close_date: closeDateFilters.start ? closeDateFilters.start.format('YYYY-MM-DD') : '',
    end_close_date: closeDateFilters.end ? closeDateFilters.end.format('YYYY-MM-DD') : '',
    start_canceled_date: canceledDateFilters.start ? canceledDateFilters.start.format('YYYY-MM-DD') : '',
    end_canceled_date: canceledDateFilters.end ? canceledDateFilters.end.format('YYYY-MM-DD') : '',
    salesperson_ids: <any>[],
    client_ids: <any>[],
    vendor_ids: <any>[],
    product_type_ids: <any>[],
    category_ids: <any>[],
    types: <any>[],
    jobTypes: <any>[],
    memo: 0,
    exclude_adjustments: excludeAdjustments,
    include_job_cost_gross_profit: includeJobCostGrossProfit,
  };

  if (salespersonIds && salespersonIds.length > 0) {
    payload.salesperson_ids = flatMap(salespersonIds, property('value'));
  }

  if (types && types.length > 0) {
    payload.types = flatMap(types, property('value'));
  }

  if (vendorIds && vendorIds.length > 0) {
    payload.vendor_ids = flatMap(vendorIds, property('value'));
  }

  if (productTypeIds && productTypeIds.length > 0) {
    payload.product_type_ids = flatMap(productTypeIds, property('value'));
  }

  if (categoryIds.length > 0) {
    payload.category_ids = flatMap(categoryIds, property('value'));
  }

  if (jobTypes && jobTypes.length > 0) {
    payload.jobTypes = flatMap(jobTypes, property('value'));
  }

  payload.memo = 0;

  if (memo) {
    payload.memo = memo.value;
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Post',
    path: `reports/sales-by-location`,
    payload,
    metaDispatch: { key },
  });
};

export const getMerchItemsReport = key => async (dispatch, ownState) => {
  const { dateFilters, locationIds, vendorIds, categoryIds, type, allocated, memo } = ownState().reports[key];

  const payload = {
    type,
    allocated,
    memo,
    start_date: dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '',
    end_date: dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '',
  } as any;

  if (locationIds && locationIds.length > 0) {
    payload.location_ids = flatMap(locationIds, property('value'));
  }

  if (vendorIds && vendorIds.length > 0) {
    payload.vendor_ids = flatMap(vendorIds, property('value'));
  }

  if (categoryIds.length > 0) {
    payload.category_ids = flatMap(categoryIds, property('value'));
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Post',
    path: `reports/merch-items`,
    payload,
    metaDispatch: { key },
  });
};

export const getCostOfGoodsSoldReport = (key: string) => async (dispatch, ownState) => {
  const { vendorIds, locationIds, skuCategoryIds, dateFilters, memo } = ownState().reports.costOfGoodsSold;

  const params = new URLSearchParams({
    startDate: dateFilters?.start?.format('YYYY-MM-DD HH:mm:ss') || '',
    endDate: dateFilters?.end?.format('YYYY-MM-DD HH:mm:ss') || '',
  });

  if (vendorIds?.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (locationIds?.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  if (skuCategoryIds?.length > 0) {
    params.append('skuCategoryIds', flatMap(skuCategoryIds, property('value')).toString());
  }

  if (memo) {
    params.append('memo', memo.toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/cost-of-goods-sold${params ? '?' : ''}${params}`,
    metaDispatch: { key },
  });
};

export const getCostOfGoodsSoldDetailReport = (key: string, skuCategoryId: string) => async (dispatch, ownState) => {
  const { vendorIds, locationIds, dateFilters, memo } = ownState().reports.costOfGoodsSold;

  const params = new URLSearchParams({
    startDate: dateFilters?.start?.format('YYYY-MM-DD HH:mm:ss') || '',
    endDate: dateFilters?.end?.format('YYYY-MM-DD HH:mm:ss') || '',
  });

  if (vendorIds?.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (locationIds?.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  params.append('skuCategoryIds', skuCategoryId);

  if (memo) {
    params.append('memo', memo.toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/cost-of-goods-sold-detail${params ? '?' : ''}${params}`,
    metaDispatch: { key },
  });
};

export const getCostOfGoodsSoldSummaryReport = (key: string) => async (dispatch, ownState) => {
  const { locationIds, dateFilters, memo } = ownState().reports.costOfGoodsSoldSummary;

  const params = new URLSearchParams({
    startDate: dateFilters?.start?.format('YYYY-MM-DD HH:mm:ss') || '',
    endDate: dateFilters?.end?.format('YYYY-MM-DD HH:mm:ss') || '',
  });

  if (locationIds?.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  if (memo) {
    params.append('memo', memo.toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/cost-of-goods-sold-summary${params ? '?' : ''}${params}`,
    metaDispatch: { key },
  });
};

export const getCostOfGoodsSoldSummaryDetailReport =
  (key: string, accountingBucketId: string) => async (dispatch, ownState) => {
    const { locationIds, dateFilters, memo } = ownState().reports.costOfGoodsSoldSummary;

    const params = new URLSearchParams({
      startDate: dateFilters?.start?.format('YYYY-MM-DD HH:mm:ss') || '',
      endDate: dateFilters?.end?.format('YYYY-MM-DD HH:mm:ss') || '',
    });

    if (locationIds?.length > 0) {
      params.append('locationIds', flatMap(locationIds, property('value')).toString());
    }

    params.append('accountingBucketId', accountingBucketId);

    if (memo) {
      params.append('memo', memo.toString());
    }

    await _apiRequest({
      actionBase: GET_REPORT,
      dispatch,
      method: 'Get',
      path: `reports/cost-of-goods-sold-summary-detail${params ? '?' : ''}${params}`,
      metaDispatch: { key },
    });
  };

export const getStockCategoryPerformanceParams = (report, overrides: any = {}): URLSearchParams => {
  const reportWithOverrides = { ...report, ...overrides };
  const {
    closeDateFilters,
    transactionDateFilters,
    locationIds,
    vendorIds,
    skuCategoryIds,
    productTypeIds,
    includeOpen,
    memo,
  } = reportWithOverrides;

  const params = new URLSearchParams({ includeOpen: includeOpen.toString() });

  if (transactionDateFilters?.start && transactionDateFilters?.end) {
    params.append('transactionStartDate', transactionDateFilters.start.format('YYYY-MM-DD HH:mm:ss'));
    params.append('transactionEndDate', transactionDateFilters.end.format('YYYY-MM-DD HH:mm:ss'));
  }

  if (closeDateFilters?.start && closeDateFilters?.end) {
    params.append('closeStartDate', closeDateFilters.start.format('YYYY-MM-DD HH:mm:ss'));
    params.append('closeEndDate', closeDateFilters.end.format('YYYY-MM-DD HH:mm:ss'));
  }

  if (locationIds?.length) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  if (vendorIds?.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (skuCategoryIds?.length) {
    params.append('skuCategoryIds', flatMap(skuCategoryIds, property('value')).toString());
  }

  if (productTypeIds?.length) {
    params.append('productTypeIds', flatMap(productTypeIds, property('value')).toString());
  }

  if (memo) {
    params.append('memo', memo.toString());
  }

  return params;
};
export const getStockCategoryPerformanceReport = (key: string) => async (dispatch, ownState) => {
  const params = getStockCategoryPerformanceParams(ownState().reports.stockCategoryPerformance);

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/stock-category-performance${params ? '?' : ''}${params}`,
    metaDispatch: { key },
  });
};
export const getDistributionReport = (key: string) => async (dispatch, ownState) => {
  const { skuCategoryIds, vendorIds } = ownState().reports.distribution;

  const params = new URLSearchParams();

  if (vendorIds?.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (skuCategoryIds?.length) {
    params.append('skuCategoryIds', flatMap(skuCategoryIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/distribution${params ? '?' : ''}${params}`,
    metaDispatch: { key },
  });
};
export const getTransactionsReport = () => async (dispatch, ownState) => {
  const {
    dateFilters,
    closeDateFilters,
    canceledDateFilters,
    salespersonIds,
    locationIds,
    clients,
    types,
    jobTypes,
    status,
    accounts,
    excludeZeroBalance,
  } = ownState().reports.transactions;

  const params = new URLSearchParams({
    status,
    excludeZeroBalance,
  });

  params.append('startDate', dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '');
  params.append('endDate', dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '');
  params.append('startCloseDate', closeDateFilters.start ? closeDateFilters.start.format('YYYY-MM-DD') : '');
  params.append('endCloseDate', closeDateFilters.end ? closeDateFilters.end.format('YYYY-MM-DD') : '');
  params.append('startCanceledDate', canceledDateFilters.start ? canceledDateFilters.start.format('YYYY-MM-DD') : '');
  params.append('endCanceledDate', canceledDateFilters.end ? canceledDateFilters.end.format('YYYY-MM-DD') : '');

  if (salespersonIds && salespersonIds.length > 0) {
    params.append('salespersonIds', flatMap(salespersonIds, property('value')).toString());
  }

  if (locationIds && locationIds.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  if (clients && clients.length > 0) {
    params.append('clientIds', flatMap(clients, property('id')).toString());
  }

  if (types && types.length > 0) {
    params.append('types', flatMap(types, property('value')).toString());
  }

  if (jobTypes && jobTypes.length > 0) {
    params.append('jobTypes', flatMap(jobTypes, property('value')).toString());
  }

  if (accounts && accounts.length > 0) {
    params.append('accountIds', flatMap(accounts, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/transactions?${params.toString()}`,
    metaDispatch: { key: 'transactions' },
  });
};

export const getClientsReport = () => async (dispatch, ownState) => {
  const {
    createDateFilters,
    closeDateFilters,
    purchaseDateFilters,
    salespersonIds,
    locationIds,
    vendorIds,
    minSpend,
    maxSpend,
    lastPurchasedWithin,
    transactionTypeIds,
    marketingChannels,
    hasAllEmailAdresses,
  } = ownState().reports.clients;

  const params = new URLSearchParams({ topClients: '1' });

  params.append('startDate', createDateFilters.start ? moment(createDateFilters.start).format('YYYY-MM-DD') : '');
  params.append('endDate', createDateFilters.end ? moment(createDateFilters.end).format('YYYY-MM-DD') : '');

  params.append('startCloseDate', closeDateFilters.start ? moment(closeDateFilters.start).format('YYYY-MM-DD') : '');
  params.append('endCloseDate', closeDateFilters.end ? moment(closeDateFilters.end).format('YYYY-MM-DD') : '');

  params.append(
    'startPurchaseDate',
    purchaseDateFilters.start ? moment(purchaseDateFilters.start).format('YYYY-MM-DD') : ''
  );
  params.append('endPurchaseDate', purchaseDateFilters.end ? moment(purchaseDateFilters.end).format('YYYY-MM-DD') : '');

  params.append('lastPurchasedWithin', lastPurchasedWithin);

  params.append('hasAllEmailAdresses', hasAllEmailAdresses);

  if (salespersonIds && salespersonIds.length > 0) {
    params.append('salespersonIds', flatMap(salespersonIds, property('value')).toString());
  }

  if (transactionTypeIds && transactionTypeIds.length > 0) {
    params.append('transactionTypeIds', flatMap(transactionTypeIds, property('value')).toString());
  }

  if (locationIds && locationIds.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  if (vendorIds && vendorIds.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (minSpend > 0) {
    params.append('minSpend', Math.round(minSpend).toString());
  }

  if (maxSpend > 0) {
    params.append('maxSpend', Math.round(maxSpend).toString());
  }

  if (marketingChannels && marketingChannels.length > 0) {
    params.append('marketingChannels', flatMap(marketingChannels, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/clients?${params.toString()}`,
    metaDispatch: { key: 'clients' },
  });
};

export const getMyClientsReport = () => async (dispatch, ownState) => {
  const {
    createDateFilters,
    closeDateFilters,
    salespersonIds,
    locationIds,
    vendorIds,
    minSpend,
    maxSpend,
    lastPurchasedWithin,
    inclusionType,
    hasAllEmailAdresses,
  } = ownState().reports.myClients;

  const params = new URLSearchParams({ topClients: '1' });

  params.append('startDate', createDateFilters.start ? moment(createDateFilters.start).format('YYYY-MM-DD') : '');
  params.append('endDate', createDateFilters.end ? moment(createDateFilters.end).format('YYYY-MM-DD') : '');

  params.append('startCloseDate', closeDateFilters.start ? moment(closeDateFilters.start).format('YYYY-MM-DD') : '');
  params.append('endCloseDate', closeDateFilters.end ? moment(closeDateFilters.end).format('YYYY-MM-DD') : '');

  params.append('lastPurchasedWithin', lastPurchasedWithin);

  params.append('hasAllEmailAdresses', hasAllEmailAdresses);

  params.append('inclusionType', inclusionType);

  if (salespersonIds && salespersonIds.length > 0) {
    params.append('salespersonIds', flatMap(salespersonIds, property('value')).toString());
  }

  if (locationIds && locationIds.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  if (vendorIds && vendorIds.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (minSpend > 0) {
    params.append('minSpend', Math.round(minSpend).toString());
  }

  if (maxSpend > 0) {
    params.append('maxSpend', Math.round(maxSpend).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/my-clients?${params.toString()}`,
    metaDispatch: { key: 'myClients' },
  });
};

export const getPaymentsReport = () => async (dispatch, ownState) => {
  const {
    dateFilters,
    locationIds,
    accountingLocationIds,
    paymentIds,
    types,
    salespersonIds,
    includeStoreCredit,
    includeCollections,
  } = ownState().reports.payments;

  const params = new URLSearchParams({
    startDate: dateFilters?.start?.format('YYYY-MM-DD'),
    endDate: dateFilters?.end?.format('YYYY-MM-DD'),
    includeStoreCredit,
    includeCollections,
  });

  if (types && types.length > 0) {
    params.append('types', flatMap(types, property('value')).toString());
  }

  if (locationIds?.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  if (accountingLocationIds?.length > 0) {
    params.append('accountingLocationIds', flatMap(accountingLocationIds, property('value')).toString());
  }

  if (paymentIds?.length > 0) {
    params.append('paymentIds', flatMap(paymentIds, property('code')).toString());
  }

  if (salespersonIds?.length > 0) {
    params.append('salespersonIds', flatMap(salespersonIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/payments?${params.toString()}`,
    metaDispatch: { key: 'payments' },
  });
};

export const getDailyPaymentsReport =
  (locationId: string | number | null | undefined, startDate: string | null, endDate: string | null) =>
  async (dispatch, ownState) => {
    if (!startDate || !endDate) {
      console.error('Date parameters must be provided');
      return;
    }

    const { includeStoreCredit } = ownState().reports.dailyPayments;

    const params = {
      startDate,
      endDate,
      locationId: String(locationId),
      includeStoreCredit,
    };

    const urlSearchParams = new URLSearchParams(params);

    await _apiRequest({
      actionBase: GET_DAILY_PAYMENTS_REPORT,
      dispatch,
      method: 'Get',
      path: `reports/daily-payments?${urlSearchParams.toString()}`,
      metaDispatch: {
        key: 'dailyPayments',
        locationId,
      },
    });
  };

export const getAccountsReceivableBalanceReport =
  (asOfDate: null, offset, limit, isExcludeStoreCredit) => async dispatch => {
    if (!asOfDate) {
      console.error('Date parameter must be provided');
      return;
    }

    const params = {
      asOfDate,
      offset,
      limit,
      isExcludeStoreCredit,
    } as any;

    const urlSearchParams = new URLSearchParams(params);

    await _apiRequest({
      actionBase: GET_ACCOUNTS_RECEIVABLE_BALANCE_REPORT,
      dispatch,
      method: 'Get',
      path: `reports/accounts-receivable-balance?${urlSearchParams.toString()}`,
      metaDispatch: {
        key: 'accountsReceivableBalance',
      },
    });
  };

export const getAccountsReceivableReport =
  (asOfDate: null, offset, limit, isExcludeStoreCredit) => async (dispatch, ownState) => {
    if (!asOfDate) {
      console.error('Date parameter must be provided');
      return;
    }

    const params = {
      asOfDate,
      offset,
      limit,
      isExcludeStoreCredit,
    } as any;

    const { customerId } = ownState().reports.accountsReceivable;

    if (customerId) {
      params.customerId = customerId;
    }

    const urlSearchParams = new URLSearchParams(params);

    await _apiRequest({
      actionBase: GET_ACCOUNTS_RECEIVABLE_REPORT,
      dispatch,
      method: 'Get',
      path: `reports/accounts-receivable?${urlSearchParams.toString()}`,
      metaDispatch: {
        key: 'accountsReceivable',
      },
    });
  };

export const getCollectionReport =
  ({ asOfDate, lastPaymentDate, clients, creditPlans }) =>
  async dispatch => {
    const params = {} as any;

    if (asOfDate) {
      params.asOfDate = asOfDate;
    }

    if (lastPaymentDate) {
      params.lastPaymentDate = lastPaymentDate;
    }

    if (clients.length > 0) {
      params.clientIds = clients.map(c => c.id).join(',');
    }

    if (creditPlans.length > 0) {
      params.creditPlans = creditPlans.map(plan => plan.value).join(',');
    }

    const urlSearchParams = new URLSearchParams(params);

    await _apiRequest({
      actionBase: GET_REPORT,
      dispatch,
      method: 'Get',
      path: `reports/collection?${urlSearchParams.toString()}`,
      metaDispatch: { key: 'collection' },
    });
  };

export const getInventoryAdjustmentReport =
  (startDate = '', endDate = '', vendorIds, locationIds) =>
  async dispatch => {
    const params = {
      startDate,
      endDate,
    } as any;

    if (vendorIds.length > 0) {
      params.vendorIds = flatMap(vendorIds, property('value')).join(',');
    }
    if (locationIds.length > 0) {
      params.locationIds = flatMap(locationIds, property('value')).join(',');
    }

    const urlSearchParams = new URLSearchParams(params);

    await _apiRequest({
      actionBase: GET_REPORT,
      dispatch,
      method: 'Get',
      path: `reports/inventory-adjustment?${urlSearchParams.toString()}`,
      metaDispatch: { key: 'inventoryAdjustment' },
    });
  };

export const getInventoryReport = () => async (dispatch, ownState) => {
  const {
    vendorIds,
    partnerIds,
    locationIds,
    caseIds,
    categoryIds,
    asOfDate,
    includeOpen,
    allocated,
    memo,
    stock,
    certified,
    attributes,
    dateFilters,
    productTypeIds,
    showStockComponents,
  } = ownState().reports.inventory;

  const payload = {
    includeOpen,
    stock,
    certified,
    allocated,
    memo,
    startDate: dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '',
    endDate: dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '',
    asOfDate: '',
    showStockComponents,
  } as any;

  if (vendorIds.length > 0) {
    payload.vendorIds = flatMap(vendorIds, property('value')).join(',');
  }
  if (partnerIds.length > 0) {
    payload.partnerIds = flatMap(partnerIds, property('value')).join(',');
  }
  if (locationIds.length > 0) {
    payload.locationIds = flatMap(locationIds, property('value')).join(',');
  }
  if (caseIds.length > 0) {
    payload.caseIds = flatMap(caseIds, property('value')).join(',');
  }
  if (categoryIds.length > 0) {
    payload.categoryIds = flatMap(categoryIds, property('value')).join(',');
  }
  if (asOfDate) {
    payload.asOfDate = asOfDate.format('YYYY-MM-DD');
  }
  if (attributes.length) {
    payload.attributes = attributes;
  }
  if (productTypeIds.length > 0) {
    payload.productTypeIds = flatMap(productTypeIds, property('value')).join(',');
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Post',
    path: `reports/inventory`,
    payload,
    metaDispatch: { key: 'inventory' },
  });
};

export const getInventoryCaseSummaryReport = () => async (dispatch, ownState) => {
  const { locationId, sort } = ownState().reports.inventoryCaseSummary;

  const params = new URLSearchParams();

  params.append('locationId', locationId || 0);
  if (sort?.by && sort?.direction) {
    params.append('sortBy', sort.by);
    params.append('sortDirection', sort.direction);
  }

  const path = `inventory/cases?${params.toString()}`;

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path,
    metaDispatch: { key: 'inventoryCaseSummary' },
  });
};

export const getInventoryCaseDetailsReport = () => async (dispatch, ownState) => {
  const { caseUuid, sort } = ownState().reports.inventoryCaseDetails;

  const params = new URLSearchParams();

  if (sort?.by && sort?.direction) {
    params.append('sortBy', sort.by);
    params.append('sortDirection', sort.direction);
  }

  const path = `inventory/cases/${caseUuid}/stock${params.toString() !== '' ? `?${params.toString()}` : ''}`;

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path,
    metaDispatch: { key: 'inventoryCaseDetails' },
  });
};

export const getRepairsReport = () => async (dispatch, ownState) => {
  const { types, status, dateFilters, closeDateFilters, recentStatus } = ownState().reports.repairs;

  const params = new URLSearchParams();

  if (types && types.length > 0) {
    params.append('types', flatMap(types, property('value')).toString());
  }

  params.append('status', status ? status.value : 0);
  params.append('startDate', dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '');
  params.append('endDate', dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '');
  params.append('startCloseDate', closeDateFilters.start ? closeDateFilters.start.format('YYYY-MM-DD') : '');
  params.append('endCloseDate', closeDateFilters.end ? closeDateFilters.end.format('YYYY-MM-DD') : '');

  params.append('recentStatus', recentStatus ? recentStatus.value : 0);

  const path = `reports/repairsReport?${params.toString()}`;

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path,
    metaDispatch: { key: 'repairs' },
  });
};

export const getLinkedProductsReport = () => async (dispatch, ownState) => {
  const { dateFilters, vendorIds, locationIds, productIds, types, status } = ownState().reports.linkedProducts;

  const params = new URLSearchParams({
    status,
  });

  if (dateFilters.start && dateFilters.start !== '') {
    params.append('startDate', dateFilters.start.format('YYYY-MM-DD'));
  }

  if (dateFilters.end && dateFilters.end !== '') {
    params.append('endDate', dateFilters.end.format('YYYY-MM-DD'));
  }

  if (productIds.length > 0) {
    params.append('productIds', flatMap(productIds, property('value')).toString());
  }

  if (types && types.length > 0) {
    params.append('types', flatMap(types, property('value')).toString());
  }

  if (vendorIds.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (locationIds && locationIds.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/linked-products?${params.toString()}`,
    metaDispatch: { key: 'linkedProducts' },
  });
};

export const getMemosReport = key => async (dispatch, ownState) => {
  const { dateFilters, locationIds, salespersonIds, product, skuIds, clients } = ownState().reports[key];

  const payload = {
    start_date: dateFilters.start ? dateFilters.start.format('YYYY-MM-DD') : '',
    end_date: dateFilters.end ? dateFilters.end.format('YYYY-MM-DD') : '',
    location_ids: <any>[],
    salesperson_ids: <any>[],
    sku_ids: <any>[],
    client_ids: <any>[],
    product: 0,
  };

  if (locationIds && locationIds.length > 0) {
    payload.location_ids = flatMap(locationIds, property('value'));
  }

  if (salespersonIds && salespersonIds.length > 0) {
    payload.salesperson_ids = flatMap(salespersonIds, property('value'));
  }

  if (skuIds && skuIds.length > 0) {
    payload.sku_ids = flatMap(skuIds, property('value'));
  }

  if (clients && clients.length > 0) {
    payload.client_ids = flatMap(clients, property('id'));
  }

  if (product) {
    payload.product = product.id;
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Post',
    path: `reports/memo-items`,
    payload,
    metaDispatch: { key },
  });
};

export const getTransferItemsReport = key => async (dispatch, ownState) => {
  const { sourceIds, destinationIds, createdByIds, receivedByIds, vendorIds, status } = ownState().reports[key];

  const params = new URLSearchParams({
    status: `${status.value}`,
  });

  if (sourceIds && sourceIds.length > 0) {
    params.append('sourceIds', flatMap(sourceIds, property('value')).toString());
  }

  if (destinationIds && destinationIds.length > 0) {
    params.append('destinationIds', flatMap(destinationIds, property('value')).toString());
  }

  if (createdByIds && createdByIds.length > 0) {
    params.append('createdByIds', flatMap(createdByIds, property('value')).toString());
  }

  if (receivedByIds && receivedByIds.length > 0) {
    params.append('receivedByIds', flatMap(receivedByIds, property('value')).toString());
  }

  if (vendorIds && vendorIds.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/transfer-items?${params.toString()}`,
    metaDispatch: { key },
  });
};

export const getPurchaseOrdersReport = key => async (dispatch, ownState) => {
  const { sourceIds, destinationIds, createdByIds, receivedByIds, vendorIds, status } = ownState().reports[key];

  const params = new URLSearchParams({
    status,
  });

  if (sourceIds && sourceIds.length > 0) {
    params.append('sourceIds', flatMap(sourceIds, property('value')).toString());
  }

  if (destinationIds && destinationIds.length > 0) {
    params.append('destinationIds', flatMap(destinationIds, property('value')).toString());
  }

  if (createdByIds && createdByIds.length > 0) {
    params.append('createdByIds', flatMap(createdByIds, property('value')).toString());
  }

  if (receivedByIds && receivedByIds.length > 0) {
    params.append('receivedByIds', flatMap(receivedByIds, property('value')).toString());
  }

  if (vendorIds && vendorIds.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/purchase-orders?${params.toString()}`,
    metaDispatch: { key },
  });
};

export const getOnAccountReport = () => async (dispatch, ownState) => {
  const { asOfDate, collectedBetweenDateFilters } = ownState().reports.onAccount;

  const params = new URLSearchParams();

  if (asOfDate) {
    params.append('asOfDate', asOfDate.format('YYYY-MM-DD'));
  }

  if (collectedBetweenDateFilters.start && collectedBetweenDateFilters.end) {
    const afterDate = collectedBetweenDateFilters.start.format('YYYY-MM-DD');
    const beforeDate = collectedBetweenDateFilters.end.format('YYYY-MM-DD');
    params.append('collectedAfterDate', afterDate);
    params.append('collectedBeforeDate', beforeDate);
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/on-account?${params.toString()}`,
    metaDispatch: { key: 'onAccount' },
  });
};

export const getGiftCardsReport = () => async (dispatch, ownState) => {
  const { createdDateFilters, cardTypes, expirationDateFilters } = ownState().reports.giftCards;

  const params = new URLSearchParams({});

  params.append(
    'startCreatedDate',
    createdDateFilters.start ? moment(createdDateFilters.start).format('YYYY-MM-DD') : ''
  );
  params.append('endCreatedDate', createdDateFilters.end ? moment(createdDateFilters.end).format('YYYY-MM-DD') : '');

  params.append(
    'startExpirationDate',
    expirationDateFilters.start ? moment(expirationDateFilters.start).format('YYYY-MM-DD') : ''
  );
  params.append(
    'endExpirationDate',
    expirationDateFilters.end ? moment(expirationDateFilters.end).format('YYYY-MM-DD') : ''
  );

  if (cardTypes && cardTypes.length > 0) {
    params.append('cardTypes', flatMap(cardTypes, `'${property('label')}'`).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/gift-cards?${params.toString()}`,
    metaDispatch: { key: 'giftCards' },
  });
};

export const getWishlistReport = (isUser, key) => async (dispatch, ownState) => {
  const { dateFilters, vendorIds, productIds, salespersonIds } = ownState().reports[key];

  const params = new URLSearchParams({
    isUser: isUser ? '1' : '0',
  });

  if (dateFilters.start && dateFilters.start !== '') {
    params.append('startDate', dateFilters.start.format('YYYY-MM-DD'));
  }

  if (dateFilters.end && dateFilters.end !== '') {
    params.append('endDate', dateFilters.end.format('YYYY-MM-DD'));
  }

  if (productIds.length > 0) {
    params.append('productIds', flatMap(productIds, property('value')).toString());
  }

  if (vendorIds.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (salespersonIds && salespersonIds.length > 0) {
    params.append('salespersonIds', flatMap(salespersonIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/wishlist?${params.toString()}`,
    metaDispatch: { key },
  });
};

export const getSalesTaxThresholdReport = () => async (dispatch, ownState) => {
  const { closeDateFilters, locationIds } = ownState().reports.salesTaxThreshold;

  const params = new URLSearchParams({});

  params.append('startCloseDate', closeDateFilters.start ? moment(closeDateFilters.start).format('YYYY-MM-DD') : '');

  params.append('endCloseDate', closeDateFilters.end ? moment(closeDateFilters.end).format('YYYY-MM-DD') : '');

  if (locationIds.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_SALES_TAX_THRESHOLD_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/sales-tax-threshold?${params.toString()}`,
    metaDispatch: { key: 'salesTaxThreshold' },
  });
};

export const getDailyOnAccountReport = (payload, cb?) => async dispatch => {
  const params = new URLSearchParams({});

  params.append('startDate', payload.startDate ? moment(payload.startDate).format('YYYY-MM-DD') : '');
  params.append('endDate', payload.endDate ? moment(payload.endDate).format('YYYY-MM-DD') : '');

  if (payload.clientId) {
    params.append('clientId', payload.clientId);
  }

  params.append('locationId', payload.locationId);

  await _apiRequest({
    actionBase: GET_DAILY_ON_ACCOUNT_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/daily-on-account?${params.toString()}`,
    callback: () => {
      cb && cb();
    },
    metaDispatch: { key: 'dailyOnAccount' },
  });
};

export const getProductPerformanceReport = (key: string) => async (dispatch, ownState) => {
  const { vendorIds, locationIds, dateFilters } = ownState().reports.productPerformance;

  const params = new URLSearchParams({
    startDate: dateFilters?.start.format('YYYY-MM-DD') || '',
    endDate: dateFilters?.end?.format('YYYY-MM-DD') || '',
  });

  if (vendorIds?.length > 0) {
    params.append('vendorIds', flatMap(vendorIds, property('value')).toString());
  }

  if (locationIds?.length > 0) {
    params.append('locationIds', flatMap(locationIds, property('value')).toString());
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/product-performance${params ? '?' : ''}${params}`,
    metaDispatch: { key },
  });
};

export const getTimeClockReport = key => async (dispatch, ownState) => {
  const { dateFilters, location } = ownState().reports[key];

  const params = new URLSearchParams({});

  params.append('startDate', dateFilters[0] ? moment(dateFilters[0]).format('YYYY-MM-DD') : '');
  params.append('endDate', dateFilters[1] ? moment(dateFilters[1]).format('YYYY-MM-DD') : '');

  if (location) {
    params.append('locationId', location.value);
  }

  await _apiRequest({
    actionBase: GET_REPORT,
    dispatch,
    method: 'Get',
    path: `reports/time-clock?${params.toString()}`,
    metaDispatch: { key },
  });
};

export const setReportProperty = (report, prop, value) => ({
  type: SET_REPORT_PROPERTY,
  payload: { report, prop, value },
});

export const setReportProperties = (report, properties) => ({
  type: SET_REPORT_PROPERTIES,
  payload: { report, properties },
});

export const resetReportProperty = (report, prop) => ({
  type: RESET_REPORT_PROPERTY,
  payload: { report, prop },
});

export const resetReport = (key, opts) => ({
  type: RESET_REPORT,
  payload: { key, opts },
});

export const getReportLayouts = id => async dispatch => {
  await _apiRequest({
    actionBase: GET_REPORT_LAYOUTS,
    dispatch,
    method: 'Get',
    path: `reports/layouts?report_id=${id}`,
    payload: null,
    returnPayloadOnSuccess: false,
  });
};

export const setReportLayout = options => async dispatch => {
  await _apiRequest({
    actionBase: SET_REPORT_LAYOUT,
    dispatch,
    method: 'Post',
    path: `reports/layout`,
    payload: options,
    returnPayloadOnSuccess: false,
    notification: 'Layout created',
  });
};

export const removeReportLayout = payload => async dispatch => {
  await _apiRequest({
    actionBase: REMOVE_REPORT_LAYOUT,
    dispatch,
    method: 'Delete',
    path: `reports/layout`,
    payload,
    returnPayloadOnSuccess: true,
    notification: 'Layout removed',
  });
};

export const setReportAttributeFilters = (filters, checkMap) => ({
  type: SET_REPORTS_ATTRIBUTE_FILTERS,
  payload: {
    filters,
    checkMap,
  },
});

export const setLayoutsModalVisible = isVisible => ({
  type: SET_LAYOUTS_MODAL_VISIBLE,
  payload: isVisible,
});

export const setNewLayoutModalVisible = isVisible => ({
  type: SET_NEW_LAYOUT_MODAL_VISIBLE,
  payload: isVisible,
});

export const setReportsDrawerVisible = isVisible => ({
  type: SET_REPORTS_DRAWER_VISIBLE,
  payload: isVisible,
});

export const setSalesComparisonColumns = (columns, columnExtensions) => ({
  type: SET_SALES_COMPARISON_COLUMNS,
  payload: {
    columns,
    columnExtensions,
  },
});

export const setStockCategoryPerformanceComparisonColumns = (columns, columnExtensions) => ({
  type: SET_STOCK_CATEGORY_PERFORMANCE_COMPARISON_COLUMNS,
  payload: {
    columns,
    columnExtensions,
  },
});
