import {
  ACCOUNTS_RECEIVABLE_REPORT_FIELDS,
  CLIENTS_REPORT_FIELDS,
  COST_OF_GOODS_SOLD_REPORT_FIELDS,
  COST_OF_GOODS_SOLD_SUMMARY_REPORT_FIELDS,
  DAILY_PAYMENTS_REPORT_FIELDS,
  GIFT_CARDS_REPORT_FIELDS,
  INVENTORY_CASE_DETAILS_FIELD,
  INVENTORY_CASE_SUMMARY_FIELDS,
  INVENTORY_REPORT_FIELDS,
  LINKED_PRODUCTS_REPORT_FIELDS,
  MEMOS_REPORT_FIELDS,
  MERCH_ITEMS_REPORT_FIELDS,
  ON_ACCOUNT_REPORT_FIELDS,
  PAYMENTS_REPORT_FIELDS,
  PRODUCT_PERFORMANCE_REPORT_FIELDS,
  REPAIRS_REPORT_FIELDS,
  SALES_BY_LOCATION_REPORT_FIELDS,
  SALES_PERFORMANCE_REPORT_FIELDS,
  SALES_TAX_THRESHOLD_REPORT_FIELDS,
  STOCK_CATEGORY_PERFORMANCE_REPORT_FIELDS,
  TRANSACTIONS_REPORT_FIELDS,
  TRANSFER_ITEMS_REPORT_FIELDS,
  VENDOR_PERFORMANCE_REPORT_FIELDS,
  VENDOR_REPORT_FIELDS,
  WISHLIST_REPORT_FIELDS,
} from 'components/Reports/Fields/table-fields';
import moment from 'moment';
import { USA_STATE_CODES } from 'strings/constants';
import { AllReports, ReportType } from 'types/api/report';

import { ACCOUNTS_RECEIVABLE_BALANCE_REPORT_FIELDS } from 'components/Reports/Fields/accounts-receivable-balance-fields';
import { COLLECTION_REPORT_FIELDS } from 'components/Reports/Fields/collection-fields';
import { INVENTORY_ADJUSTMENT_REPORT_FIELDS } from 'components/Reports/Fields/inventory-adjustment-fields';
import i18n from 'i18next';
import { successReqActionType } from '../actions/helpers';
import {
  GET_ACCOUNTS_RECEIVABLE_BALANCE_REPORT,
  GET_ACCOUNTS_RECEIVABLE_REPORT,
  GET_ATTRIBUTES,
  GET_DAILY_ON_ACCOUNT_REPORT,
  GET_DAILY_PAYMENTS_REPORT,
  GET_REPORT,
  GET_REPORT_LAYOUTS,
  GET_SALES_TAX_THRESHOLD_REPORT,
  REMOVE_REPORT_LAYOUT,
  RESET_ALL_DATA,
  RESET_REPORT,
  RESET_REPORT_PROPERTY,
  SET_LAYOUTS_MODAL_VISIBLE,
  SET_NEW_LAYOUT_MODAL_VISIBLE,
  SET_REPORTS_ATTRIBUTE_FILTERS,
  SET_REPORTS_DRAWER_VISIBLE,
  SET_REPORT_LAYOUT,
  SET_REPORT_PROPERTIES,
  SET_REPORT_PROPERTY,
  SET_SALES_COMPARISON_COLUMNS,
  SET_STOCK_CATEGORY_PERFORMANCE_COMPARISON_COLUMNS,
} from '../constants/action-types';

const initialState = {
  mySales: {
    reportId: ReportType.MySales,
    reportKey: 'mySales',
    dateFilters: [null, null],
    closeDateFilters: [null, null],
    canceledDateFilters: [null, null],
    salespersonIds: [],
    clients: [],
    locationIds: [],
    vendorIds: [],
    linkedVendorIds: [],
    productTypeIds: [],
    categoryIds: [],
    skuIds: [],
    attributes: [],
    types: [],
    jobTypes: [],
    status: 0,
    includeRepairs: 0,
    taxable: 0,
    stock: 0,
    split: 1,
    rollUps: true,
    memo: null,
    data: [],
    fields: [],
    snapshot: {
      status: 0,
      stock: 0,
      includeRepairs: 0,
      taxable: 0,
    },
    dateArrays: ['dateFilters', 'closeDateFilters', 'canceledDateFilters'],
    renderRows: true,
    title: 'My Sales',
  },
  sales: {
    reportId: ReportType.SalesItems,
    reportKey: 'sales',
    dateFilters: [null, null],
    closeDateFilters: [null, null],
    canceledDateFilters: [null, null],
    salespersonIds: [],
    locationIds: [],
    vendorIds: [],
    linkedVendorIds: [],
    clients: [],
    productTypeIds: [],
    categoryIds: [],
    skuIds: [],
    types: [],
    jobTypes: [],
    attributes: [],
    status: 0,
    includeRepairs: 0,
    excludeAdjustments: 0,
    taxable: 0,
    stock: 0,
    split: 1,
    rollUps: true,
    memo: null,
    data: [],
    fields: [],
    snapshot: {
      status: 0,
      stock: 0,
      includeRepairs: 0,
      taxable: 0,
    },
    dateArrays: ['dateFilters', 'closeDateFilters', 'canceledDateFilters'],
    renderRows: true,
    title: 'My Sales Items',
  },
  salesComparison: {
    columns: [],
    columnExtensions: [],
  },
  costOfGoodsSold: {
    reportId: ReportType.CostOfGoodsSold,
    reportKey: 'costOfGoodsSold',
    dateFilters: {},
    locationIds: [],
    vendorIds: [],
    skuCategoryIds: [],
    memo: 0,
    data: [],
    renderRows: true,
    fields: COST_OF_GOODS_SOLD_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.title')
      ? i18n.t('reports:cost-of-goods-sold.title')
      : 'Cost of Goods Sold Report',
  },
  costOfGoodsSoldDetail: {
    reportId: ReportType.CostOfGoodsSoldDetail,
    reportKey: 'costOfGoodsSoldDetail',
    memo: 0,
    data: [],
    renderRows: true,
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.title')
      ? i18n.t('reports:cost-of-goods-sold.title')
      : 'Cost of Goods Sold Report',
  },
  costOfGoodsSoldSummary: {
    reportId: ReportType.CostOfGoodsSoldSummary,
    reportKey: 'costOfGoodsSoldSummary',
    dateFilters: {},
    locationIds: [],
    memo: 0,
    data: [],
    renderRows: true,
    fields: COST_OF_GOODS_SOLD_SUMMARY_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.summary-title')
      ? i18n.t('reports:cost-of-goods-sold.summary-title')
      : 'Cost of Goods Sold Summary Report',
  },
  costOfGoodsSoldSummaryDetail: {
    reportId: ReportType.CostOfGoodsSoldSummaryDetail,
    reportKey: 'costOfGoodsSoldSummaryDetail',
    memo: 0,
    data: [],
    renderRows: true,
    title: i18n.hasResourceBundle('en', 'reports:cost-of-goods-sold.summary-title')
      ? i18n.t('reports:cost-of-goods-sold.summary-title')
      : 'Cost of Goods Sold Summary Report',
  },
  stockCategoryPerformance: {
    reportId: ReportType.StockCategoryPerformance,
    reportKey: 'stockCategoryPerformance',
    transactionDateFilters: {},
    closeDateFilters: {},
    locationIds: [],
    productTypeIds: [],
    vendorIds: [],
    includeOpen: 0,
    skuCategoryIds: [],
    memo: 0,
    data: [],
    renderRows: true,
    fields: STOCK_CATEGORY_PERFORMANCE_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    title: i18n.hasResourceBundle('en', 'reports:stock-category-performance.title')
      ? i18n.t('reports:stock-category-performance.title')
      : 'Stock Category Performance Report',
  },
  distribution: {
    reportId: ReportType.Distribution,
    reportKey: 'distribution',
    vendorIds: [],
    skuCategoryIds: [],
    data: [],
    renderRows: true,
    fields: [],
    title: i18n.hasResourceBundle('en', 'reports:distribution.title')
      ? i18n.t('reports:distribution.title')
      : 'Distribution Report',
  },
  stockCategoryPerformanceComparison: {
    columns: [],
    columnExtensions: [],
  },
  transactions: {
    reportId: ReportType.Transactions,
    reportKey: 'transactions',
    accounts: [],
    dateFilters: [null, null],
    closeDateFilters: [null, null],
    canceledDateFilters: [null, null],
    salespersonIds: [],
    locationIds: [],
    clients: [],
    types: [],
    jobTypes: [],
    status: 0,
    rollUps: true,
    excludeZeroBalance: 0,
    data: [],
    fields: TRANSACTIONS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {
      status: 0,
    },
    dateArrays: ['dateFilters', 'closeDateFilters', 'canceledDateFilters'],
    renderRows: true,
    title: 'Transactions',
  },
  clients: {
    reportId: ReportType.Clients,
    reportKey: 'clients',
    createDateFilters: [null, null],
    closeDateFilters: [null, null],
    purchaseDateFilters: [null, null],
    transactionTypeIds: [],
    salespersonIds: [],
    locationIds: [],
    vendorIds: [],
    marketingChannels: [],
    minSpend: null,
    maxSpend: null,
    rollUps: true,
    lastPurchasedWithin: 0,
    hasAllEmailAdresses: 0,
    data: [],
    fields: CLIENTS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['closeDateFilters', 'createDateFilters', 'purchaseDateFilters'],
    renderRows: true,
    title: 'Clients',
  },
  collection: {
    asOfDate: null,
    clients: [],
    creditPlans: [],
    lastPaymentDate: null,
    fields: COLLECTION_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    renderRows: true,
    reportId: ReportType.Collection,
    reportKey: AllReports[ReportType.Collection].key,
    dateFields: ['asOfDate', 'lastPaymentDate'],
    snapshot: {},
    title: 'Collection',
  },
  inventory: {
    reportId: ReportType.Inventory,
    reportKey: 'inventory',
    rollUps: true,
    locationIds: [],
    allocated: 0,
    memo: 0,
    stock: 1,
    includeOpen: 0,
    certified: 0,
    vendorIds: [],
    partnerIds: [],
    productTypeIds: [],
    caseIds: [],
    categoryIds: [],
    attributes: [],
    asOfDate: null,
    data: [],
    fields: INVENTORY_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateFilters: [null, null],
    dateArrays: ['dateFilters'],
    dateFields: ['asOfDate'],
    renderRows: true,
    title: 'Inventory',
  },
  inventoryAdjustment: {
    dateArrays: ['dateFilters'],
    dateFilters: [null, null],
    fields: INVENTORY_ADJUSTMENT_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    locationIds: [],
    renderRows: true,
    reportId: ReportType.InventoryAdjustment,
    reportKey: 'inventoryAdjustment',
    snapshot: {},
    title: 'Inventory Adjustment',
    vendorIds: [],
  },
  repairs: {
    reportId: ReportType.Repairs,
    reportKey: 'repairs',
    types: [],
    completed: false,
    dateFilters: [null, null],
    closeDateFilters: [null, null],
    data: [],
    fields: REPAIRS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    status: null,
    recentStatus: null,
    snapshot: {
      status: null,
    },
    dateArrays: ['dateFilters', 'closeDateFilters'],
    renderRows: true,
    title: 'Repairs',
  },
  payments: {
    reportId: ReportType.Payments,
    reportKey: 'payments',
    dateFilters: [null, null],
    types: [],
    locationIds: [],
    paymentIds: [],
    salespersonIds: [],
    rollUps: true,
    data: [],
    fields: PAYMENTS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['dateFilters'],
    renderRows: true,
    includeStoreCredit: 0,
    includeCollections: 0,
    title: 'Payments',
  },
  dailyPayments: {
    reportId: ReportType.DailyPayments,
    reportKey: 'dailyPayments',
    dateFilters: [null, null],
    locationId: null,
    data: [],
    fields: DAILY_PAYMENTS_REPORT_FIELDS.map(f => f.key),
    snapshot: {},
    dateArrays: ['dateFilters'],
    renderRows: true,
    includeStoreCredit: 0,
    title: 'Daily Payments',
  },
  linkedProducts: {
    reportId: ReportType.LinkedProducts,
    reportKey: 'linkedProducts',
    dateFilters: [null, null],
    vendorIds: [],
    locationIds: [],
    reference: null,
    orderType: null,
    status: 1,
    data: [],
    fields: LINKED_PRODUCTS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {
      status: 1,
    },
    productIds: [],
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'Linked Products',
  },
  vendor: {
    reportId: ReportType.Vendor,
    reportKey: 'vendor',
    vendorIds: [],
    dateFilters: [null, null],
    age: 365,
    data: [],
    fields: VENDOR_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'Vendor',
  },
  wishlist: {
    reportId: ReportType.ClientWishlist,
    reportKey: 'wishlist',
    salespersonIds: [],
    dateFilters: [null, null],
    vendorIds: [],
    reference: null,
    data: [],
    fields: WISHLIST_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    productIds: [],
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'Client Wishlist',
  },
  myWishlist: {
    reportId: ReportType.MyClientWishlist,
    reportKey: 'myWishlist',
    salespersonIds: [],
    dateFilters: [null, null],
    vendorIds: [],
    reference: null,
    data: [],
    fields: WISHLIST_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    productIds: [],
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'My Client Wishlist',
  },
  merchItems: {
    reportId: ReportType.MerchandiseItems,
    reportKey: 'merchItems',
    dateFilters: [null, null],
    locationIds: [],
    vendorIds: [],
    categoryIds: [],
    allocated: 0,
    memo: 0,
    type: 1,
    data: [],
    fields: MERCH_ITEMS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'Merchandise Items',
  },
  onAccount: {
    reportId: ReportType.OnAccount,
    reportKey: 'onAccount',
    asOfDate: null,
    collectedBetweenDateFilters: [null, null],
    rollUps: true,
    data: [],
    fields: ON_ACCOUNT_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: [],
    renderRows: true,
    title: 'On Account',
  },
  giftCards: {
    reportId: ReportType.GiftCards,
    reportKey: 'giftCards',
    createdDateFilters: [null, null],
    expirationDateFilters: [null, null],
    cardTypes: [],
    type: 1,
    data: [],
    fields: GIFT_CARDS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {
      type: 1,
    },
    dateArrays: ['createdDateFilters', 'expirationDateFilters'],
    renderRows: true,
    title: 'Gift Cards',
  },
  accountingSummary: {
    reportId: ReportType.AccountingSummary,
    reportKey: 'accountingSummary',
    closeDateFilters: [null, null],
    locationIds: [],
    data: [],
    paymentData: [],
    monthSelected: moment(),
    snapshot: {},
    dateArrays: ['dateFilters', 'closeDateFilters', 'canceledDateFilters'],
    renderRows: true,
    title: 'Accounting Summary',
  },
  memos: {
    reportId: ReportType.MemoItems,
    reportKey: 'memos',
    salespersonIds: [],
    locationIds: [],
    skuIds: [],
    product: null,
    clients: [],
    data: [],
    fields: MEMOS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateFilters: [null, null],
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'Memo Items',
  },
  myClients: {
    reportId: ReportType.MyClients,
    reportKey: 'myClients',
    createDateFilters: [null, null],
    closeDateFilters: [null, null],
    salespersonIds: [],
    locationIds: [],
    vendorIds: [],
    minSpend: null,
    maxSpend: null,
    rollUps: true,
    lastPurchasedWithin: 0,
    hasAllEmailAdresses: 0,
    data: [],
    fields: CLIENTS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['closeDateFilters', 'createDateFilters'],
    renderRows: true,
    title: 'My Clients',
    inclusionType: 'ownedByMe',
  },
  salesPerformance: {
    reportId: ReportType.SalespersonPerformance,
    reportKey: 'salesPerformance',
    closeDateFilters: [null, null],
    rollUps: true,
    data: [],
    fields: SALES_PERFORMANCE_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['closeDateFilters'],
    renderRows: true,
    title: 'Salesperson Performance',
  },
  vendorPerformance: {
    reportId: ReportType.VendorPerformance,
    reportKey: 'vendorPerformance',
    closeDateFilters: [null, null],
    salespersonIds: [],
    clients: [],
    locationIds: [],
    selectedVendors: [],
    linkedVendorIds: [],
    productTypeIds: [],
    attributes: [],
    types: [],
    jobTypes: [],
    rollUps: true,
    data: [],
    fields: VENDOR_PERFORMANCE_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['dateFilters', 'closeDateFilters'],
    renderRows: true,
    title: 'Vendor Performance',
  },
  salesTaxThreshold: {
    reportId: ReportType.SalesTaxThreshold,
    reportKey: 'salesTaxThreshold',
    closeDateFilters: {},
    locationIds: [],
    locations: [],
    data: [],
    response: {
      orders: [],
      state_tax_thresholds: [],
    },
    fields: SALES_TAX_THRESHOLD_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateArrays: ['dateFilters', 'closeDateFilters'],
    renderRows: true,
    title: 'Sales Tax Threshold',
  },
  accountsReceivable: {
    reportId: ReportType.AccountsReceivable,
    reportKey: 'accountsReceivable',
    asOfDate: null,
    customerId: null,
    data: [],
    fields: ACCOUNTS_RECEIVABLE_REPORT_FIELDS.map(f => f.key),
    snapshot: {},
    renderRows: true,
    title: 'Accounts Receivable',
  },
  accountsReceivableBalance: {
    reportId: ReportType.AccountsReceivableBalance,
    reportKey: 'accountsReceivableBalance',
    asOfDate: null,
    data: [],
    fields: ACCOUNTS_RECEIVABLE_BALANCE_REPORT_FIELDS.map(f => f.key),
    snapshot: {},
    renderRows: true,
    title: 'Accounts Receivable Balance',
  },
  transferItems: {
    reportId: ReportType.TransferItems,
    reportKey: 'transferItems',
    vendorIds: [],
    sourceIds: [],
    destinationIds: [],
    status: 0,
    createdByIds: [],
    receivedByIds: [],
    data: [],
    fields: TRANSFER_ITEMS_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {},
    dateFilters: [null, null],
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'Transfer Items',
  },
  dailyOnAccount: {
    reportId: ReportType.DailyOnAccount,
    reportKey: 'dailyOnAccount',
    monthSelected: moment().startOf('month'),
    data: {},
    renderRows: true,
    title: 'Daily On Account',
  },
  purchaseOrders: {
    reportId: ReportType.PurchaseOrders,
    reportKey: 'purchaseOrders',
    vendorIds: [],
    sourceIds: [],
    destinationIds: [],
    status: 0,
    createdByIds: [],
    receivedByIds: [],
    data: [],
    fields: [],
    snapshot: {},
    dateFilters: [null, null],
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: 'Purchase Orders',
  },
  productPerformance: {
    reportId: ReportType.ProductPerformance,
    reportKey: 'productPerformance',
    vendorIds: [],
    locationIds: [],
    snapshot: {},
    fields: PRODUCT_PERFORMANCE_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    dateFilters: [null, null],
    data: [],
    dateArrays: ['dateFilters'],
    renderRows: true,
    title: i18n.hasResourceBundle('en', 'reports:product-performance')
      ? i18n.t('reports:product-performance')
      : 'Product Performance',
  },
  inventoryCaseSummary: {
    reportId: ReportType.InventoryCaseSummary,
    reportKey: 'inventoryCaseSummary',
    fields: INVENTORY_CASE_SUMMARY_FIELDS,
    data: [],
    locationId: 0,
    sort: { by: '', direction: '' },
    snapshot: {},
    dateFilters: [],
    dateArrays: [],
    renderRows: true,
    title: i18n.hasResourceBundle('en', 'inventory:inventory-cases.inventory-case-summary-report')
      ? i18n.t('inventory:inventory-cases.inventory-case-summary-report')
      : 'Inventory Case Summary',
  },
  inventoryCaseDetails: {
    reportId: ReportType.InventoryCaseDetails,
    reportKey: 'inventoryCaseDetails',
    fields: INVENTORY_CASE_DETAILS_FIELD,
    data: [],
    locationId: 0,
    sort: { by: '', direction: '' },
    snapshot: {},
    dateFilters: [],
    dateArrays: [],
    renderRows: true,
    title: i18n.hasResourceBundle('en', 'inventory:inventory-cases.inventory-case-details-report')
      ? i18n.t('inventory:inventory-cases.inventory-case-details-report')
      : 'Inventory Case Details',
  },
  timeClock: {
    reportId: ReportType.TimeClock,
    reportKey: 'timeClock',
    monthSelected: moment(),
    fields: [],
    data: [],
    location: {
      value: '',
      label: 'All',
    },
    sort: { by: '', direction: '' },
    snapshot: {},
    dateFilters: [null, null],
    dateArrays: [],
    renderRows: true,
    title: 'Time Clock',
  },
  salesByLocation: {
    reportId: ReportType.SalesByLocation,
    reportKey: 'salesByLocation',
    dateFilters: [null, null],
    closeDateFilters: {
      label: 'Today',
      value: 'today',
      start: moment().startOf('day'),
      end: moment().endOf('day'),
    },
    canceledDateFilters: [null, null],
    salespersonIds: [],
    vendorIds: [],
    productTypeIds: [],
    categoryIds: [],
    types: [],
    jobTypes: [],
    status: 0,
    includeRepairs: 1,
    excludeAdjustments: 0,
    rollUps: true,
    memo: null,
    data: [],
    fields: SALES_BY_LOCATION_REPORT_FIELDS.filter(f => f.isDefault).map(f => f.key),
    snapshot: {
      status: 0,
      includeRepairs: 0,
    },
    dateArrays: ['dateFilters', 'closeDateFilters', 'canceledDateFilters'],
    renderRows: true,
    title: 'Sales By Location',
  },
  layouts: [],
  payload: null,
  filters: [],
  checkMap: [],
  productAttributesMap: [],
  layoutsModalVisible: false,
  newLayoutModalVisible: false,
  drawerVisible: false,
};

const LARGE_REPORT_THRESHOLD = 1000;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successReqActionType(GET_REPORT):
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          renderRows: action.payload.length <= LARGE_REPORT_THRESHOLD,
          [action.subKey || 'data']: action.payload,
        },
      };
    case successReqActionType(GET_DAILY_PAYMENTS_REPORT):
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          renderRows: true,
          response: action.payload,
          data: action.locationId
            ? action.payload.payments.filter(result => result.location_id === Number(action.locationId))
            : action.payload.payments,
        },
      };
    case successReqActionType(GET_SALES_TAX_THRESHOLD_REPORT):
      const response = action.payload;
      const locations = state.salesTaxThreshold.locations;

      // build container of orders with valid state codes
      const validStateCodes = Object.keys(USA_STATE_CODES);
      const validOrders = response.orders.filter(order => validStateCodes.includes(order.state_code.toUpperCase()));

      // map state tax thresholds to add fields storing current totals for sales and quantity
      const thresholds = response.state_tax_thresholds.map(state_tax_threshold => {
        return {
          ...state_tax_threshold,
          total_sales: {},
          total_transactions: {},
          locations,
        };
      });

      const formattedSalesTaxThresholdData = thresholds;

      // loop through orders, and add each order to the proper state threshold by location ID
      validOrders.forEach(validOrder => {
        const associatedThreshold = formattedSalesTaxThresholdData.find(
          threshold => threshold.state_code === validOrder.state_code
        );

        if (!associatedThreshold) {
          // threshold missing, skip this iter
          return;
        }

        // add to total sales ( create index if it does not yet exist )
        if (!associatedThreshold.total_sales[validOrder.location_id]) {
          associatedThreshold.total_sales[validOrder.location_id] = 0;
        }
        associatedThreshold.total_sales[validOrder.location_id] += validOrder.ext_price;
        // add to total quantity ( create index if it does not yet exist )
        if (!associatedThreshold.total_transactions[validOrder.location_id]) {
          associatedThreshold.total_transactions[validOrder.location_id] = 0;
        }
        associatedThreshold.total_transactions[validOrder.location_id] += validOrder.quantity;
      });

      // set as report data
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          renderRows: false,
          data: formattedSalesTaxThresholdData.sort((a, b) => {
            if (a.state_name < b.state_name) {
              return -1;
            }
            if (a.state_name > b.state_name) {
              return 1;
            }
            return 0;
          }),
        },
      };
    case SET_REPORT_PROPERTY: {
      return {
        ...state,
        [action.payload.report]: {
          ...state[action.payload.report],
          [action.payload.prop]: action.payload.value,
        },
      };
    }
    case SET_REPORT_PROPERTIES: {
      return {
        ...state,
        [action.payload.report]: {
          ...state[action.payload.report],
          ...action.payload.properties,
        },
      };
    }
    case RESET_REPORT: {
      return {
        ...state,
        [action.payload.key]: {
          ...initialState[action.payload.key],
          ...action.payload.opts,
        },
      };
    }
    case successReqActionType(GET_REPORT_LAYOUTS):
      return {
        ...state,
        layouts: action.payload,
      };
    case successReqActionType(SET_REPORT_LAYOUT):
      return {
        ...state,
        layouts: [...state.layouts, action.payload],
      };
    case successReqActionType(REMOVE_REPORT_LAYOUT):
      return {
        ...state,
        layouts: [...state.layouts.filter(layout => layout.id !== action.payload.id)],
      };
    case RESET_REPORT_PROPERTY: {
      return {
        ...state,
        [action.payload.report]: {
          ...state[action.payload.report],
          [action.payload.prop]: initialState[action.payload.report][action.payload.prop],
          snapshot: {
            ...state[action.payload.report].snapshot,
            [action.payload.prop]: null,
          },
        },
      };
    }
    case successReqActionType(GET_ATTRIBUTES):
      return {
        ...state,
        productAttributesMap: {
          ...state.productAttributesMap,
          [action.vendorId]: action.payload,
        },
      };
    case SET_REPORTS_ATTRIBUTE_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
        checkMap: action.payload.checkMap,
      };
    case SET_LAYOUTS_MODAL_VISIBLE: {
      return {
        ...state,
        drawerVisible: action.payload ? false : state.drawerVisible,
        layoutsModalVisible: action.payload,
      };
    }
    case SET_NEW_LAYOUT_MODAL_VISIBLE: {
      return {
        ...state,
        drawerVisible: action.payload ? false : state.drawerVisible,
        newLayoutModalVisible: action.payload,
      };
    }
    case SET_REPORTS_DRAWER_VISIBLE: {
      return {
        ...state,
        drawerVisible: action.payload,
      };
    }
    case SET_SALES_COMPARISON_COLUMNS: {
      return {
        ...state,
        salesComparison: {
          columns: [...action.payload.columns],
          columnExtensions: [...action.payload.columnExtensions],
        },
      };
    }
    case SET_STOCK_CATEGORY_PERFORMANCE_COMPARISON_COLUMNS: {
      return {
        ...state,
        stockCategoryPerformanceComparison: {
          columns: [...action.payload.columns],
          columnExtensions: [...action.payload.columnExtensions],
        },
      };
    }
    case successReqActionType(GET_ACCOUNTS_RECEIVABLE_BALANCE_REPORT):
    case successReqActionType(GET_ACCOUNTS_RECEIVABLE_REPORT):
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          renderRows: action?.payload?.data?.length <= LARGE_REPORT_THRESHOLD,
          [action.subKey || 'data']: action.payload?.data,
        },
      };
    case successReqActionType(GET_DAILY_ON_ACCOUNT_REPORT):
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          renderRows: action?.payload?.data?.length <= LARGE_REPORT_THRESHOLD,
          [action.subKey || 'data']: action.payload,
        },
      };
    case RESET_ALL_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
