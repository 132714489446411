import { Link } from 'react-router-dom';
import { formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const PAYMENTS_REPORT_FIELDS = [
  {
    name: 'Date',
    key: 'payment_date',
    id: 'payment_date',
    sortable: true,
    filterable: true,
    type: 'DATE',
    isDefault: true,
    showInGroupedReport: true,
    render: (row: ReportRow) => formatLocalDate(row.payment_date),
  },
  {
    name: 'Amount',
    key: 'amount',
    id: 'amount',
    sortable: true,
    isDefault: true,
    filterable: true,
    showInGroupedReport: true,
    type: 'NUMBER',
    isCurrency: true,
    render: (row: ReportRow) => formatMoney(row.amount),
  },
  {
    name: 'Payment Type',
    key: 'payment_type',
    id: 'payment_type',
    showInGroupedReport: true,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Status',
    id: 'status',
    key: 'status',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Transaction #',
    key: 'transaction_id',
    id: 'transaction_id',
    isDefault: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transactions/${row.order_uuid}`}
      >
        {row.transaction_id}
      </Link>
    ),
    sortable: true,
    filterable: true,
    showInGroupedReport: true,
    type: 'LINK',
  },
  {
    name: 'Transaction Type',
    key: 'transaction_type',
    id: 'transaction_type',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson Name',
    key: 'salesperson_name',
    id: 'salesperson_name',
    isDefault: true,
    showInGroupedReport: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Client #',
    key: 'customer_id',
    id: 'customer_id',
    isDefault: true,
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_id}
      </Link>
    ),
    sortable: true,
    filterable: true,
    type: 'LINK',
  },
  {
    name: 'Client Name',
    key: 'customer_name',
    id: 'customer_name',
    showInGroupedReport: true,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Location',
    key: 'location_name',
    id: 'location_name',
    showInGroupedReport: true,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Accounting Location',
    key: 'accounting_location_name',
    id: 'accounting_location_name',
    showInGroupedReport: true,
    isDefault: true,
    sortable: true,
    filterable: true,
  },
];
