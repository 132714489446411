import { Link } from 'react-router-dom';
import { formatDate, formatLocalDate } from 'utils/date-utils';
import { formatMoney } from 'utils/money';
import { ReportRow } from './field_types';

export const TRANSACTIONS_REPORT_FIELDS = [
  {
    name: 'Transaction Date',
    id: 'transaction_date',
    key: 'transaction_date',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.transaction_date),
  },
  {
    name: 'Close Date',
    id: 'close_date',
    key: 'close_date',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.close_date),
  },
  {
    name: 'Canceled Date',
    id: 'canceled_date',
    key: 'canceled_date',
    type: 'DATE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatDate(row.canceled_date),
  },
  {
    name: 'Transaction #',
    id: 'transaction_id',
    key: 'transaction_id',
    isDefault: true,
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/transactions/${row.order_uuid}`}
      >
        {row.transaction_id}
      </Link>
    ),
  },
  {
    name: 'Status',
    id: 'status',
    key: 'status',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Transaction Type',
    id: 'transaction_type',
    key: 'transaction_type',
    isDefault: true,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Tracking #',
    id: 'tracking_number',
    key: 'tracking_number',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Last Payment Date',
    id: 'last_pay_date',
    key: 'last_pay_date',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => row.last_pay_date && row.last_pay_date.length > 0 && formatLocalDate(row.last_pay_date),
  },
  {
    name: 'Due Date',
    id: 'due_date',
    key: 'due_date',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'DATE',
    render: (row: ReportRow) => formatLocalDate(row.due_date),
  },
  {
    name: 'Client Name',
    id: 'customer_name',
    key: 'customer_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Client #',
    id: 'customer_id',
    key: 'customer_id',
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) => (
      <Link
        className='table-row-link'
        id='app-link'
        onClick={e => e.stopPropagation()}
        to={`/clients/${row.customer_uuid}`}
      >
        {row.customer_id}
      </Link>
    ),
  },
  {
    name: 'Location',
    id: 'location_name',
    key: 'location_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Salesperson Name',
    id: 'salesperson_name',
    key: 'salesperson_name',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Linked Product',
    id: 'product.uuid',
    key: 'product.uuid',
    sortable: true,
    filterable: true,
    type: 'LINK',
    render: (row: ReportRow) =>
      row.product && (
        <Link
          className='table-row-link'
          id='app-link'
          onClick={e => e.stopPropagation()}
          to={`/products/${row.product.uuid}`}
        >
          {row.product.reference}
        </Link>
      ),
  },
  {
    name: 'Quantity',
    id: 'quantity',
    key: 'quantity',
    isDefault: false,
    sortable: true,
    filterable: true,
    type: 'NUMBER',
  },
  {
    name: 'Retail',
    id: 'retail',
    key: 'retail',
    sortable: true,
    isDefault: false,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.retail),
  },
  {
    name: 'Ext. Price',
    id: 'ext_price',
    key: 'ext_price',
    sortable: true,
    isDefault: false,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.ext_price),
  },
  {
    name: 'Discount',
    id: 'discount',
    key: 'discount',
    sortable: true,
    isDefault: false,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.discount),
  },
  {
    name: 'Subtotal',
    id: 'subtotal',
    key: 'subtotal',
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.subtotal),
  },
  {
    name: 'Tax',
    id: 'tax',
    key: 'tax',
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.tax),
  },
  {
    name: 'Total',
    id: 'total',
    key: 'total',
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.total),
  },
  {
    name: 'Amount Paid',
    id: 'amount_paid',
    key: 'amount_paid',
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.amount_paid),
  },
  {
    name: 'Balance',
    id: 'balance',
    key: 'balance',
    sortable: true,
    isDefault: true,
    filterable: true,
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.balance),
  },
  {
    name: 'Ext. Cost',
    key: 'ext_cost',
    id: 'ext_cost',
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.ext_cost),
    sortable: true,
    filterable: true,
  },
  {
    name: 'Ext. Job Cost',
    key: 'ext_job_cost',
    id: 'ext_job_cost',
    type: 'NUMBER',
    render: (row: ReportRow) => formatMoney(row.ext_job_cost),
    sortable: true,
    filterable: true,
  },
  {
    name: 'Note',
    key: 'note',
    id: 'note',
    render: (row: ReportRow) => row.note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Internal Note',
    key: 'internal_note',
    id: 'internal_note',
    render: (row: ReportRow) => row.internal_note,
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Source',
    id: 'marketing_source',
    key: 'marketing_source',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Alternative Source',
    id: 'marketing_source_2',
    key: 'marketing_source_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Street',
    id: 'billing_street',
    key: 'billing_street',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Street2',
    id: 'billing_street_2',
    key: 'billing_street_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - City',
    id: 'billing_city',
    key: 'billing_city',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - State',
    id: 'billing_state',
    key: 'billing_state',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Postal',
    id: 'billing_postal',
    key: 'billing_postal',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Billing - Country',
    id: 'billing_country',
    key: 'billing_country',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Street',
    id: 'shipping_street',
    key: 'shipping_street',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Street2',
    id: 'shipping_street_2',
    key: 'shipping_street_2',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - City',
    id: 'shipping_city',
    key: 'shipping_city',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - State',
    id: 'shipping_state',
    key: 'shipping_state',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Postal',
    id: 'shipping_postal',
    key: 'shipping_postal',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Shipping - Country',
    id: 'shipping_country',
    key: 'shipping_country',
    sortable: true,
    filterable: true,
  },
  {
    name: 'Tax Rate',
    id: 'tax_rate',
    key: 'tax_rate',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => `${(Number(row.tax_rate) * 100).toFixed(2)}%`,
  },
  {
    name: 'Most Recent Status',
    key: 'status_name',
    id: 'status_name',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Est. To Repair',
    key: 'estimate_to_repair',
    id: 'estimate_to_repair',
    type: 'NUMBER',
    isDefault: false,
    sortable: true,
    filterable: true,
    render: (row: ReportRow) => formatMoney(row.estimate_to_repair || 0),
  },
  {
    name: 'Account',
    key: 'account_name',
    id: 'account_name',
    isDefault: false,
    sortable: true,
    filterable: true,
  },
  {
    name: 'Image',
    id: 'image',
    key: 'image',
    type: 'IMAGE',
    sortable: true,
    filterable: true,
    render: (row: ReportRow) =>
      row.primary_attachment && (
        <img
          alt=''
          id='product-image-table'
          src={row.primary_attachment}
          style={{ maxHeight: '100px', minHeight: '50px' }}
        />
      ),
  },
];
