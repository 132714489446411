import { i18nNamespaces } from 'i18n';
import { useTranslation } from 'react-i18next';

export const SIGN_IN_TEXT = 'Sign in to Crystal';
export const PASSWORD_RESET_TEXT = 'Reset Your Password';

export const FEEDBACK_TEXT =
  'Please provide a brief message below and we will respond as soon as possible. If you are reporting a bug, please include the steps to reproduce and relevant record # if possible (such as Client # or Transaction #). For assistance, please contact our toll-free line at (224) 307-6803.';

export const VENDOR_SEARCH_TITLE = 'Include In Browse Inventory';
export const INCLUDE_ECOMMERCE = 'Include In E-Commerce';

export const SERVER_DOWN = 'The server is currently undergoing maintenance. Please check back soon!';

export const BEGIN_SEARCH = 'Click the filter button to begin your inventory search.';

export const NO_SEARCH_RESULTS = 'There were no results matching your filters.';

export const PLACEHOLDER_IMAGE_SRC = 'https://diam-general.s3.us-east-2.amazonaws.com/general/item_placeholder.png';

export const PDF_WORKER_URL = 'https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js';

export const ALLOCATED_OPTIONS = [
  { key: 0, value: 'All' },
  { key: 1, value: 'Allocated' },
  { key: 2, value: 'Not Allocated' },
];

export const STOCK_OPTIONS = [
  { key: 0, value: 'All', textKey: 'all' },
  { key: 1, value: 'In Stock', textKey: 'instock' },
  { key: 2, value: 'Out of Stock', textKey: 'outofstock' },
];

export const STATUS_OPTIONS = [
  { key: 0, value: 'All' },
  { key: 1, value: 'Open' },
  { key: 2, value: 'Closed' },
  { key: 3, value: 'Canceled' },
];

export const TRANSFER_STATUS_OPTIONS = [
  { value: 1, label: 'All' },
  { value: 2, label: 'Pending' },
  { value: 3, label: 'Completed' },
];

export const TAXABLE_OPTIONS = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Taxable' },
  { value: 2, label: 'Not Taxable' },
];

export const REPORT_STOCK_OPTIONS = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Stock #s' },
  { value: 2, label: 'Nonstock #s' },
];

export const CARD_TYPE_OPTIONS = [
  { value: 1, label: 'Advertising' },
  { value: 2, label: 'Donation' },
  { value: 3, label: 'Sold' },
  { value: 4, label: 'Other' },
];

export const CLIENT_CONTACT_TYPES = [
  { key: 0, value: 'Home' },
  { key: 1, value: 'Work' },
  { key: 2, value: 'Mobile' },
];

export const NEW_CLIENT_CONTACT_TYPES = [
  { value: 'Home', label: 'Home' },
  { value: 'Work', label: 'Work' },
  { value: 'Mobile', label: 'Mobile' },
];

export const MEMO_OPTIONS = [
  { value: 0, label: 'All' },
  { value: 1, label: 'Memo' },
  { value: 2, label: 'Asset' },
];

export const ACCOUNT_TYPES = [
  { value: 1, label: 'Vendor' },
  { value: 2, label: 'Retailer' },
  { value: 3, label: 'Partner' },
  { value: 4, label: 'Manufacturer' },
];

export const useECommerceOptions = () => {
  const { t } = useTranslation([i18nNamespaces.Common]);
  return [
    { value: 0, label: t('all', { ns: i18nNamespaces.Common }) },
    { value: 1, label: t('no', { ns: i18nNamespaces.Common }) },
    { value: 2, label: t('yes', { ns: i18nNamespaces.Common }) },
  ];
};

export const usePurchaseOrderStatusOptions = () => {
  const { t } = useTranslation([i18nNamespaces.Common]);
  return [
    { value: 0, label: t('all') },
    { value: 1, label: t('open') },
    { value: 2, label: t('closed') },
  ];
};

export const USA_STATE_CODES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const CRYSTAL_DEMO_SYSTEM_NAME = 'crystal-demo';

export const useMarketingChannelOptions = () => {
  const { t } = useTranslation([i18nNamespaces.Order]);

  return [
    {
      label: t('marketing-channel-options.current-customer', {
        ns: i18nNamespaces.Order,
      }),
      value: 'CRNT-CUST',
    },
    {
      label: t('marketing-channel-options.family-referral', {
        ns: i18nNamespaces.Order,
      }),
      value: 'FAM-RFRL',
    },
    {
      label: t('marketing-channel-options.friend-referral', {
        ns: i18nNamespaces.Order,
      }),
      value: 'FRND-RFRL',
    },
    {
      label: t('marketing-channel-options.google-search', {
        ns: i18nNamespaces.Order,
      }),
      value: 'G-SRCH',
    },
    {
      label: t('marketing-channel-options.magazine', {
        ns: i18nNamespaces.Order,
      }),
      value: 'MGZN',
    },
    {
      label: t('marketing-channel-options.newspaper', {
        ns: i18nNamespaces.Order,
      }),
      value: 'NWSPPR',
    },
    {
      label: t('marketing-channel-options.radio', {
        ns: i18nNamespaces.Order,
      }),
      value: 'RADIO',
    },
    {
      label: t('marketing-channel-options.ig', {
        ns: i18nNamespaces.Order,
      }),
      value: 'IG',
    },
    {
      label: t('marketing-channel-options.fb', {
        ns: i18nNamespaces.Order,
      }),
      value: 'FB',
    },
    {
      label: t('marketing-channel-options.tw', {
        ns: i18nNamespaces.Order,
      }),
      value: 'TW',
    },
    {
      label: t('marketing-channel-options.pinterest', {
        ns: i18nNamespaces.Order,
      }),
      value: 'PTRST',
    },
    {
      label: t('marketing-channel-options.li', {
        ns: i18nNamespaces.Order,
      }),
      value: 'LI',
    },
    {
      label: t('marketing-channel-options.yt', {
        ns: i18nNamespaces.Order,
      }),
      value: 'YT',
    },
    {
      label: t('marketing-channel-options.other-social-media', {
        ns: i18nNamespaces.Order,
      }),
      value: 'OTHR-SOC',
    },
    {
      label: t('marketing-channel-options.tv', {
        ns: i18nNamespaces.Order,
      }),
      value: 'TV',
    },
    {
      label: t('marketing-channel-options.der', {
        ns: i18nNamespaces.Order,
      }),
      value: 'DER',
    },
    {
      label: t('marketing-channel-options.other', {
        ns: i18nNamespaces.Order,
      }),
      value: 'OTHR',
    },
  ];
};

export const UnlocalizedMarketingChannelOptions = [
  {
    label: 'Current Customer',
    value: 'CRNT-CUST',
  },
  {
    label: 'Family Or Friend Referral',
    value: 'FAM-OR-FRND',
  },
  {
    label: 'Google Search',
    value: 'G-SRCH',
  },
  {
    label: 'Magazine',
    value: 'MGZN',
  },
  {
    label: 'Newspaper',
    value: 'NWSPPR',
  },
  {
    label: 'Radio',
    value: 'RADIO',
  },
  {
    label: 'Instagram',
    value: 'IG',
  },
  {
    label: 'Facebook',
    value: 'FB',
  },
  {
    label: 'Twitter',
    value: 'TW',
  },
  {
    label: 'Pinterest',
    value: 'PTRST',
  },
  {
    label: 'LinkedIn',
    value: 'LI',
  },
  {
    label: 'YouTube',
    value: 'YT',
  },
  {
    label: 'Other Social Media',
    value: 'OTHR-SOC',
  },
  {
    label: 'Television',
    value: 'TV',
  },
  {
    label: 'Other',
    value: 'OTHR',
  },
];

export const DEFAULT_CONVERSION_POTENTIAL = 0;
export const useEstimatedConversionPotentialOptions = () => {
  const { t } = useTranslation([i18nNamespaces.Clients, i18nNamespaces.Common]);

  const EstimatedConversionPotentialOptions = [
    {
      value: DEFAULT_CONVERSION_POTENTIAL,
      label: t('not-applicable', { ns: i18nNamespaces.Clients }),
    },
    {
      value: 4,
      label: t('priority-options.very-high', {
        ns: i18nNamespaces.Clients,
      }),
    },
    {
      value: 3,
      label: t('priority-options.high', {
        ns: i18nNamespaces.Clients,
      }),
    },
    {
      value: 2,
      label: t('priority-options.medium', {
        ns: i18nNamespaces.Clients,
      }),
    },
    {
      value: 1,
      label: t('priority-options.low', {
        ns: i18nNamespaces.Clients,
      }),
    },
  ];

  return EstimatedConversionPotentialOptions;
};

export const NOT_AVAILABLE = 'N/A';

export const EMPTY = '';

export const JobTypesEnum = {
  JewelryRepair: 1,
  WatchRepair: 2,
  Appraisal: 3,
  Evaluation: 4,
};

export const FORM_APPRAISAL_SUBMITTED = 1;
