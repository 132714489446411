/* eslint-disable react-hooks/exhaustive-deps */
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MuiDatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { OutlinedInputProps, TextFieldProps } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import Theme from './CrystalMUITheme';

interface DatePickerProps {
  className?: string;
  size?: string;
  value?: null;
  onChange: ((date: Date | Moment | null) => void) | Dispatch<SetStateAction<null>> | null;
  label?: string;
  readOnly?: boolean;
  disabled?: boolean;
  props?: any;
}

interface TSize {
  [key: string]: 'small' | 'medium' | undefined;
}

export default function DatePicker({
  className = '',
  size = 'md',
  value = null,
  onChange = null,
  label = '',
  readOnly,
  disabled,
  ...props
}: DatePickerProps) {
  const [pickerStatus, setPickerStatus] = React.useState(false);

  const sizes: TSize = {
    sm: 'small',
    md: 'medium',
  };

  const onChangeDate = (date: Moment | null) => {
    if (date) {
      const newDate = moment(date);
      onChange && onChange(newDate as (((prevState: null) => null) & (Date | Moment)) | null);
    } else {
      onChange && onChange(null);
    }
  };

  const newValue = value ? moment(value) : null;

  const resetDate = (e: MouseEvent) => {
    e.stopPropagation();
    onChange && onChange(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={Theme}>
        <MuiDatePicker
          /* eslint-disable-next-line */
          // @ts-ignore
          className='mui-date-picker'
          disableMaskedInput
          inputFormat='M/d/yyyy'
          label={label}
          onChange={onChangeDate}
          onClose={() => setPickerStatus(false)}
          open={pickerStatus}
          renderInput={(params: TextFieldProps) => (
            <FormControl className={`form-date-control ${className} ${size}`} size={sizes[size]} variant='outlined'>
              <InputLabel>{label}</InputLabel>
              <OutlinedInput
                onClick={() => !(readOnly || disabled) && setPickerStatus(true)}
                {...(params as OutlinedInputProps)}
                autoComplete='new-password'
                endAdornment={
                  <InputAdornment position='end'>
                    {value && (
                      <IconButton aria-label='reset time' onClick={resetDate}>
                        <i className='far fa-times-circle' />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
          value={newValue}
          {...props}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
}
